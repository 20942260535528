<template>
  <Breadcrumbs main="Gallery" title="Hover Effect" />
  <div class="container-fluid">
    <hoverEffect1 />
    <hoverEffect2 />
    <hoverEffect3 />
    <hoverEffect4 />
    <hoverEffect5 />
    <hoverEffect6 />
    <hoverEffect7 />
    <hoverEffect8 />
    <hoverEffect9 />
    <hoverEffect10 />
    <hoverEffect11 />
    <hoverEffect12 />
    <hoverEffect13 />
    <hoverEffect14 />
    <hoverEffect15 />
  </div>
</template>
<script>
import hoverEffect1 from "./hoverEffect1.vue";
import hoverEffect2 from "./hoverEffect2.vue";
import hoverEffect3 from "./hoverEffect3.vue";
import hoverEffect4 from "./hoverEffect4.vue";
import hoverEffect5 from "./hoverEffect5.vue";
import hoverEffect6 from "./hoverEffect6.vue";
import hoverEffect7 from "./hoverEffect7.vue";
import hoverEffect8 from "./hoverEffect8.vue";
import hoverEffect9 from "./hoverEffect9.vue";
import hoverEffect10 from "./hoverEffect10.vue";
import hoverEffect11 from "./hoverEffect11.vue";
import hoverEffect12 from "./hoverEffect12.vue";
import hoverEffect13 from "./hoverEffect13.vue";
import hoverEffect14 from "./hoverEffect14.vue";
import hoverEffect15 from "./hoverEffect15.vue";
export default {
  components: {
    hoverEffect1,
    hoverEffect2,
    hoverEffect3,
    hoverEffect4,
    hoverEffect5,
    hoverEffect6,
    hoverEffect7,
    hoverEffect8,
    hoverEffect9,
    hoverEffect10,
    hoverEffect11,
    hoverEffect12,
    hoverEffect13,
    hoverEffect14,
    hoverEffect15,
  },
};
</script>
