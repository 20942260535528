<template>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Animated checkbox buttons</h5>
      </div>
      <div class="card-body animate-chk">
        <div class="row">
          <div class="col">
            <label class="d-block" for="chk-ani">
              <input class="checkbox_animated" id="chk-ani" type="checkbox" checked="" />Option 1
            </label>
            <label class="d-block" for="chk-ani1">
              <input class="checkbox_animated" id="chk-ani1" type="checkbox" />Option 2
            </label>
            <label class="d-block" for="chk-ani2">
              <input class="checkbox_animated" id="chk-ani2" type="checkbox" checked="" />Option 3
            </label>
            <label class="d-block" for="chk-ani3">
              <input class="checkbox_animated" id="chk-ani3" type="checkbox" />Option 4
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Square checkbox</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="checkbox checkbox-dark m-squar">
                <input id="inline-sqr-1" type="checkbox" />
                <label class="mt-0" for="inline-sqr-1">Default</label>
              </div>
              <div class="checkbox checkbox-dark m-squar">
                <input id="inline-sqr-2" type="checkbox" disabled="" />
                <label for="inline-sqr-2">Disabled</label>
              </div>
              <div class="checkbox checkbox-dark m-squar">
                <input id="inline-sqr-3" type="checkbox" checked="" />
                <label for="inline-sqr-3">checked</label>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <h5>Solid checkbox</h5>
          </div>
          <div class="col">
            <div class="form-group m-t-15 mb-0">
              <div class="checkbox checkbox-solid-success">
                <input id="solid1" type="checkbox" />
                <label for="solid1">Default</label>
              </div>
              <div class="checkbox checkbox-solid-dark">
                <input id="solid2" type="checkbox" disabled="" />
                <label for="solid2">Disabled</label>
              </div>
              <div class="checkbox checkbox-solid-primary">
                <input id="solid3" type="checkbox" checked="" />
                <label for="solid3">checked</label>
              </div>
              <div class="checkbox checkbox-solid-danger">
                <input id="solid4" type="checkbox" checked="" />
                <label for="solid4">checked</label>
              </div>
              <div class="checkbox checkbox-solid-light">
                <input id="solid5" type="checkbox" checked="" />
                <label for="solid5">checked</label>
              </div>
              <div class="checkbox checkbox-solid-info">
                <input id="solid6" type="checkbox" checked="" />
                <label for="solid6">checked</label>
              </div>
              <div class="checkbox checkbox-solid-dark">
                <input id="solid7" type="checkbox" checked="" />
                <label class="mb-0" for="solid7">checked</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
