<template>
  <div class="col-xl-6 col-lg-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Margin Top</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .m-t-0 {
            margin-top: 0px !important;
          }
          .m-t-5 {
            margin-top: 5px !important;
          }
          .m-t-10 {
            margin-top: 10px !important;
          }
          .m-t-15 {
            margin-top: 15px !important;
          }
          .m-t-20 {
            margin-top: 20px !important;
          }
          .m-t-25 {
            margin-top: 25px !important;
          }
          .m-t-30 {
            margin-top: 30px !important;
          }
          .m-t-35 {
            margin-top: 35px !important;
          }
          .m-t-40 {
            margin-top: 40px !important;
          }
          .m-t-45 {
            margin-top: 45px !important;
          }
          .m-t-50 {
            margin-top: 50px !important;
          }
        </pre>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-lg-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Margin Bottom</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .m-b-0 {
            margin-bottom: 0px !important;
          }
          .m-b-5 {
            margin-bottom: 5px !important;
          }
          .m-b-10 {
            margin-bottom: 10px !important;
          }
          .m-b-15 {
            margin-bottom: 15px !important;
          }
          .m-b-20 {
            margin-bottom: 20px !important;
          }
          .m-b-25 {
            margin-bottom: 25px !important;
          }
          .m-b-30 {
            margin-bottom: 30px !important;
          }
          .m-b-35 {
            margin-bottom: 35px !important;
          }
          .m-b-40 {
            margin-bottom: 40px !important;
          }
          .m-b-45 {
            margin-bottom: 45px !important;
          }
          .m-b-50 {
            margin-bottom: 50px !important;
          }
        </pre>
      </div>
    </div>
  </div>
</template>
