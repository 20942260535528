<template>
  <i data-feather="message-square">
    <vue-feather type="message-square"> </vue-feather>
  </i>
  <ul class="chat-dropdown onhover-show-div">
    <li>
      <div class="media">
        <img class="img-fluid rounded-circle me-3" src="../../assets/images/user/4.jpg" alt="" />
        <div class="media-body">
          <span>Ain Chavez</span>
          <p class="f-12 light-font">What's the project report update?</p>
        </div>
        <p class="f-12">32 mins ago</p>
      </div>
    </li>
    <li>
      <div class="media">
        <img class="img-fluid rounded-circle me-3" src="../../assets/images/user/1.jpg" alt="" />
        <div class="media-body">
          <span>Erica Hughes</span>
          <p class="f-12 light-font">Send me project code...</p>
        </div>
        <p class="f-12">58 mins ago</p>
      </div>
    </li>
    <li>
      <div class="media">
        <img class="img-fluid rounded-circle me-3" src="../../assets/images/user/2.jpg" alt="" />
        <div class="media-body">
          <span>Kori Thomas</span>
          <p class="f-12 light-font">Check your report update...</p>
        </div>
        <p class="f-12">1 hr ago</p>
      </div>
    </li>
    <li class="text-center">
      <a class="f-w-700" href="/app/chat">See All </a>
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
};
</script>
