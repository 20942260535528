<template>
  <div class="loader-wrapper" :class="{ loderhide: !show }">
    <div class="loader bg-white">
      <div class="whirly-loader"></div>
    </div>
  </div>
</template>

<script>
import Userauth from "../auth/js/index";

export default {
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    this.timeOut();
    // Userauth.handleAuthentication().then(() => {
    //   this.handleAuthentication().then((x) => {
    //     window.location.href = "/dashboard/default";
    //   });
    // }
    // );
    this.handleAuthentication().then((x) => {
      window.location.href = "/dashboard/default";
    });
  },
  methods: {
    handleAuthentication() {
      let authResult = localStorage.getItem("user");
      return new Promise((resolve, reject) => {
        (err, authResults) => {
          if (authResult.id) {
            // this.localAuthLogin(authResult);
            resolve(authResult);
          } else if (err) {
            return reject(err);
          }
        };
      });
    },
    timeOut() {
      var self = this;
      setTimeout(function () {
        self.show = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
