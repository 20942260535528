<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header social-header">
        <h5>
          <span class="f-w-600">Education and Employement</span>
          <span class="pull-right">
            <i data-feather="more-vertical">
              <vue-feather type="more-vertical"> </vue-feather>
            </i>
          </span>
        </h5>
      </div>
      <div class="card-body pt-0">
        <div class="row details-about">
          <div class="col-sm-6">
            <div class="your-details">
              <h6 class="f-w-600">The New College of Design</h6>
              <p>2001 - 2006</p>
              <p>Breaking Good, RedDevil, People of Interest, The Running Dead, Found, American Guy.</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="your-details your-details-xs">
              <h6 class="f-w-600">Digital Design Intern</h6>
              <p>2006-2008</p>
              <p>
                Digital Design Intern for the “Multimedz” agency. Was in charge of the communication with the clients.
              </p>
            </div>
          </div>
        </div>
        <div class="row details-about">
          <div class="col-sm-6">
            <div class="your-details">
              <h6 class="f-w-600">Rembrandt Institute</h6>
              <p>2008</p>
              <p>Five months Digital Illustration course. Professor: Leonardo Stagg.</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="your-details your-details-xs">
              <h6 class="f-w-600">UI/UX Designer</h6>
              <p>2001 - 2006</p>
              <p>Breaking Good, RedDevil, People of Interest, The Running Dead, Found, American Guy.</p>
            </div>
          </div>
        </div>
        <div class="row details-about">
          <div class="col-sm-6">
            <div class="your-details">
              <h6 class="f-w-600">The Digital College</h6>
              <p>2010</p>
              <p>6 months intensive Motion Graphics course. After Effects and Premire. Professor: Donatello Urtle.</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="your-details your-details-xs">
              <h6 class="f-w-600">The New College of Design</h6>
              <p>2008 - 2013</p>
              <p>UI/UX Designer for the “Daydreams” agency.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
