<template>
  <welcomeView />
</template>
<script>
import router from "@/router";
import welcomeView from "../default/welcomeView.vue";

export default {
  components: {
    welcomeView,
  },
  beforeRouteEnter() {
    const check = async () => {
      const data = await localStorage.getItem("user");
      if (data == null) {
        router.replace("/auth/login");
      }
    };
    check();
    // if(localStorage.getItem('user') == null){
    //     router.replace('/auth/login')
    // }
  },
};
</script>
