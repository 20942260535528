<template>
  <div class="col-xl-6 col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Border Width</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .border-1 {
            border-width: 1px !important;
          }
          .border-2 {
            border-width: 2px !important;
          }
          .border-3 {
            border-width: 3px !important;
          }
          .border-4 {
            border-width: 4px !important;
          }
          .border-5 {
            border-width: 5px !important;
          }
          .border-6 {
            border-width: 6px !important;
          }
          .border-7 {
            border-width: 7px !important;
          }
          .border-8 {
            border-width: 8px !important;
          }
          .border-9 {
            border-width: 9px !important;
          }
          .border-10 {
            border-width: 10px !important;
          }
        </pre>
      </div>
    </div>
    <div class="card">
      <div class="card-header pb-0">
        <h5>Position</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .p-static {
            position: static;
          }
          .p-absolute {
            position: absolute;
          }
          .p-fixed {
            position: fixed;
          }
          .p-relative {
            position: relative;
          }
          .p-initial {
            position: initial;
          }
          .p-inherit {
            position: inherit;
          }
        </pre>
      </div>
    </div>
    <div class="card">
      <div class="card-header pb-0">
        <h5>Button Outline</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .btn-outline-primary {
            border-color: #ab8ce4;
            color: #ab8ce4;
            background-color: transparent;
          }
          .btn-outline-secondary {
            border-color: #26c6da;
            color: #26c6da;
            background-color: transparent;
          }
          .btn-outline-success {
            border-color: #00c292;
            color: #00c292;
            background-color: transparent;
          }
          .btn-outline-danger {
            border-color: #FF5370;
            color: #FF5370;
            background-color: transparent;
          }
          .btn-outline-info {
            border-color: #4099ff;
            color: #4099ff;
            background-color: transparent;
          }
          .btn-outline-light {
            border-color: #eeeeee;
            color: #eeeeee;
            background-color: transparent;
          }
          .btn-outline-dark {
            border-color: #2a3142;
            color: #2a3142;
            background-color: transparent;
          }
          .btn-outline-warning {
            border-color: #f3d800;
            color: #f3d800;
            background-color: transparent;
          }
        </pre>
      </div>
    </div>
  </div>
</template>
