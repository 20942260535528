<template>
  <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
    <div class="card features-faq product-box">
      <div class="faq-image product-img">
        <img class="img-fluid" src="../../assets/images/faq/3.jpg" alt="" />
        <div class="product-hover">
          <ul>
            <li>
              <a href="#">
                <i class="icon-link"> </i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="icon-import"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <a href="#"> <h6>UI Design</h6> </a>
        <p>User interface design (UI) is the design for machines and software, such as mobile devices, computers.</p>
      </div>
      <div class="card-footer">
        <span>Apr 20, 2023</span>
        <ul class="pull-right">
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star-o font-primary"> </i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
    <div class="card features-faq product-box">
      <div class="faq-image product-img">
        <img class="img-fluid" src="../../assets/images/faq/4.jpg" alt="" />
        <div class="product-hover">
          <ul>
            <li>
              <a href="#">
                <i class="icon-link"> </i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="icon-import"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <a href="#"> <h6>Php Developer</h6> </a>
        <p>This course is designed to start you on a path toward future studies in web development and design.</p>
      </div>
      <div class="card-footer">
        <span>May 01, 2023</span>
        <ul class="pull-right">
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
    <div class="card features-faq product-box">
      <div class="faq-image product-img">
        <img class="img-fluid" src="../../assets/images/faq/1.jpg" alt="" />
        <div class="product-hover">
          <ul>
            <li>
              <a href="#">
                <i class="icon-link"> </i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="icon-import"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <a href="#"> <h6>Graphic Design</h6> </a>
        <p>Web Designing course belongs to field of Computer and IT. It enables students to learn various technique.</p>
      </div>
      <div class="card-footer">
        <span>Dec 08, 2023</span>
        <ul class="pull-right">
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star-o font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star-o font-primary"> </i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
    <div class="card features-faq product-box">
      <div class="faq-image product-img">
        <img class="img-fluid" src="../../assets/images/faq/2.jpg" alt="" />
        <div class="product-hover">
          <ul>
            <li>
              <a href="#">
                <i class="icon-link"> </i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="icon-import"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <a href="#"> <h6>Developer</h6> </a>
        <p>User interface design (UI) is the design for machines and software, such as mobile devices, computers.</p>
      </div>
      <div class="card-footer">
        <span>Sept 14, 2023</span>
        <ul class="pull-right">
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star-o font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star-o font-primary"> </i>
          </li>
          <li>
            <i class="fa fa-star-o font-primary"> </i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
