<template>
  <div class="col-lg-12 featured-tutorial">
    <div class="header-faq">
      <h5 class="mb-0">Featured Tutorials</h5>
    </div>
    <div class="row">
      <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
        <div class="card features-faq product-box">
          <div class="faq-image product-img">
            <img class="img-fluid" src="../../assets/images/faq/1.jpg" alt="" />
            <div class="product-hover">
              <ul>
                <li>
                  <a href="#">
                    <i class="icon-link"> </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon-import"> </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <a href="#"> <h6>Web Design</h6> </a>
            <p>
              Web Designing course belongs to field of Computer and IT. It enables students to learn various technique.
            </p>
          </div>
          <div class="card-footer">
            <span>Jan 21, 2023</span>
            <ul class="pull-right">
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
        <div class="card features-faq product-box">
          <div class="faq-image product-img">
            <img class="img-fluid" src="../../assets/images/faq/2.jpg" alt="" />
            <div class="product-hover">
              <ul>
                <li>
                  <a href="#">
                    <i class="icon-link"> </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon-import"> </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <a href="#"> <h6>Web Development</h6> </a>
            <p>This course is designed to start you on a path toward future studies in web development and design.</p>
          </div>
          <div class="card-footer">
            <span>Dec 15, 2023</span>
            <ul class="pull-right">
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star-o font-primary"> </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
        <div class="card features-faq product-box">
          <div class="faq-image product-img">
            <img class="img-fluid" src="../../assets/images/faq/3.jpg" alt="" />
            <div class="product-hover">
              <ul>
                <li>
                  <a href="#">
                    <i class="icon-link"> </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon-import"> </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <a href="#"> <h6>UI Design</h6> </a>
            <p>
              User interface design (UI) is the design for machines and software, such as mobile devices, computers.
            </p>
          </div>
          <div class="card-footer">
            <span>Feb 10, 2023</span>
            <ul class="pull-right">
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star-o font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star-o font-primary"> </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-3 xl-50 col-sm-6 box-col-6">
        <div class="card features-faq product-box">
          <div class="faq-image product-img">
            <img class="img-fluid" src="../../assets/images/faq/4.jpg" alt="" />
            <div class="product-hover">
              <ul>
                <li>
                  <a href="#">
                    <i class="icon-link"> </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon-import"> </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <a href="#"> <h6>Tailwind Design</h6> </a>
            <p>
              Web Designing course belongs to field of Computer and IT. It enables students to learn various technique.
            </p>
          </div>
          <div class="card-footer">
            <span>Nov 05, 2023</span>
            <ul class="pull-right">
              <li>
                <i class="fa fa-star font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star-o font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star-o font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star-o font-primary"> </i>
              </li>
              <li>
                <i class="fa fa-star-o font-primary"> </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <featured2 />
    </div>
  </div>
</template>
<script>
import featured2 from "./featured2.vue";
export default {
  components: {
    featured2,
  },
};
</script>
