<template>
  <tbody>
    <tr>
      <td class="bd-t-none u-s-tb">
        <div class="align-middle image-sm-size">
          <img class="img-radius align-top m-r-15 rounded-circle" src="../../../assets/images/user/4.jpg" alt="" />
          <div class="d-inline-block">
            <h6>John Deo <span class="text-muted">(14+ Online)</span></h6>
          </div>
        </div>
      </td>
      <td>Designer</td>
      <td>
        <div class="progress-showcase">
          <div class="progress" style="height: 8px">
            <div
              class="progress-bar bg-primary"
              role="progressbar"
              style="width: 30%"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </td>
      <td>2 Year</td>
    </tr>
    <tr>
      <td class="bd-t-none u-s-tb">
        <div class="align-middle image-sm-size d-flex align-items-center">
          <img class="img-radius align-top m-r-15 rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
          <div class="d-inline-block">
            <h6>Holio Mako <span class="text-muted">(250+ Online)</span></h6>
          </div>
        </div>
      </td>
      <td>Developer</td>
      <td>
        <div class="progress-showcase">
          <div class="progress" style="height: 8px">
            <div
              class="progress-bar bg-secondary"
              role="progressbar"
              style="width: 70%"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </td>
      <td>3 Year</td>
    </tr>
    <tr>
      <td class="bd-t-none u-s-tb">
        <div class="align-middle image-sm-size">
          <img class="img-radius align-top m-r-15 rounded-circle" src="../../../assets/images/user/5.jpg" alt="" />
          <div class="d-inline-block">
            <h6>Mohsib lara<span class="text-muted">(99+ Online)</span></h6>
          </div>
        </div>
      </td>
      <td>Tester</td>
      <td>
        <div class="progress-showcase">
          <div class="progress" style="height: 8px">
            <div
              class="progress-bar bg-primary"
              role="progressbar"
              style="width: 60%"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </td>
      <td>5 Year</td>
    </tr>
    <tr>
      <td class="bd-t-none u-s-tb">
        <div class="align-middle image-sm-size">
          <img class="img-radius align-top m-r-15 rounded-circle" src="../../../assets/images/user/6.jpg" alt="" />
          <div class="d-inline-block">
            <h6>Hileri Soli <span class="text-muted">(150+ Online)</span></h6>
          </div>
        </div>
      </td>
      <td>Designer</td>
      <td>
        <div class="progress-showcase">
          <div class="progress" style="height: 8px">
            <div
              class="progress-bar bg-secondary"
              role="progressbar"
              style="width: 30%"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </td>
      <td>3 Year</td>
    </tr>
    <tr>
      <td class="bd-t-none u-s-tb">
        <div class="align-middle image-sm-size">
          <img class="img-radius align-top m-r-15 rounded-circle" src="../../../assets/images/user/7.jpg" alt="" />
          <div class="d-inline-block">
            <h6>Pusiz bia <span class="text-muted">(14+ Online)</span></h6>
          </div>
        </div>
      </td>
      <td>Designer</td>
      <td>
        <div class="progress-showcase">
          <div class="progress" style="height: 8px">
            <div
              class="progress-bar bg-primary"
              role="progressbar"
              style="width: 90%"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </td>
      <td>5 Year</td>
    </tr>
  </tbody>
</template>
<script>
export default {};
</script>
