<template>
  <div class="col-xxl-4 col-lg-6 box-col-6 debit-card">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Debit card</h5>
      </div>
      <div class="card-body">
        <form class="theme-form e-commerce-form row">
          <div class="mb-3 col-sm-6 p-r-0">
            <input class="form-control" type="text" placeholder="Full name here" />
          </div>
          <div class="mb-3 col-sm-6">
            <input class="form-control" type="text" placeholder="Card number" />
          </div>
          <div class="mb-3 col-sm-6 p-r-0">
            <input class="form-control" type="text" placeholder="CVV number" />
          </div>
          <div class="mb-3 col-sm-6">
            <input class="form-control" type="text" placeholder="CVC" />
          </div>
          <div class="col-12">
            <label class="col-form-label p-b-20">Expiration Date</label>
          </div>
          <div class="mb-3 col-sm-6 p-r-0">
            <select class="form-select" size="1">
              <option>Select Month</option>
              <option>Jan</option>
              <option>Fab</option>
              <option>March</option>
              <option>April</option>
            </select>
          </div>
          <div class="mb-3 col-sm-6">
            <select class="form-select" size="1">
              <option>Select Year</option>
              <option>2015</option>
              <option>2016</option>
              <option>2017</option>
              <option>2018</option>
              <option>2019</option>
            </select>
          </div>
          <div class="col-12">
            <button class="btn btn-primary btn-block" type="button" title="">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
