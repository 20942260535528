<template>
  <div class="col-xl-12 des-xl-50 yearly-growth-sec">
    <div class="card">
      <div class="card-header">
        <div class="header-top d-sm-flex justify-content-between align-items-center">
          <h5>Yearly growth</h5>
          <div class="center-content">
            <p class="d-sm-flex align-items-center">
              <span class="m-r-10" style="display: flex">
                <i class="toprightarrow-primary fa fa-arrow-up m-r-10 d-grid"> </i>$9657.55k </span
              >86% more then last year
            </p>
          </div>
        </div>
      </div>
      <div class="card-body p-0 chart-block">
        <yearlyGrowth2 />
      </div>
    </div>
  </div>
</template>
<script>
import yearlyGrowth2 from "../e-commerce/yearlyGrowth2.vue";
export default {
  components: {
    yearlyGrowth2,
  },
};
</script>
