<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 footer-copyright">
          <!-- NOTE: Is it legal? -->
          <!-- <p class="mb-0">Copyright 2022-23 © viho All rights reserved.</p> -->
        </div>
        <div class="col-md-6">
          <p class="pull-right mb-0">Hand crafted & made with <i class="fa fa-heart font-secondary"> </i></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import json from "../data/menu";
import VueFeather from "vue-feather";
export default {
  components: {
    VueFeather,
  },
  data() {
    return {
      customizer: false,
      data: "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json)),
    };
  },
};
</script>
