import { userService } from "../../_services";
import router from "../../router/index";

export const authentication = {
  namespaced: true,

  getters: {},
  actions: {
    // login({ dispatch, commit }, { username, passwordjwt }) {
    //     commit('loginRequest', { username });
    //     userService.login(username, passwordjwt)
    //         .then(
    //             user => {
    //                 commit('loginSuccess', user);
    //                 router.push('/');
    //             },
    //             error => {
    //                 commit('loginFailure', error);
    //                 dispatch('alert/error', error, { root: true });
    //             }
    //         );
    // },
    login({ commit }) {
      router.push("/");
      commit("login");
    },
    logout({ commit }) {
      userService.logout();
      commit("logout");
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
};
