<template>
  <div class="row">
    <div class="col-xl-6 xl-100 box-col-12">
      <p class="pb-4">About 6,000 results (0.60 seconds)</p>
      <div class="media info-block">
        <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/CJnfAXlBRTE"> </iframe>
        <div class="media-body">
          <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
          <a href="#">vihoeducation.info/</a>
          <p>viho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
          <div class="star-ratings">
            <ul class="search-info">
              <li>2.5 stars</li>
              <li>590 votes</li>
              <li>Music</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="media info-block">
        <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/-L4gEk7cOfk"> </iframe>
        <div class="media-body">
          <h6>Morbi eget quam et purus commodo dapibus.</h6>
          <a href="#">vihoeducation.info/</a>
          <p>viho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
          <div class="star-ratings">
            <ul class="search-info">
              <li>2.5 stars</li>
              <li>590 votes</li>
              <li>Music</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="media info-block">
        <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/I0-vBdh4sZ8"> </iframe>
        <div class="media-body">
          <h6>Cuba Admin Dashboard Template</h6>
          <a href="#">https://themeforest.net/</a>
          <p>viho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
          <div class="star-ratings">
            <ul class="search-info">
              <li>2.5 stars</li>
              <li>590 votes</li>
              <li>Music</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="media info-block">
        <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/wpmHZspl4EM"> </iframe>
        <div class="media-body">
          <h6>Lorem Ipsum is simply dummy text of the printing.</h6>
          <a href="#">vihoeducation.info/</a>
          <p>viho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
          <div class="star-ratings">
            <ul class="search-info">
              <li>2.5 stars</li>
              <li>590 votes</li>
              <li>Music</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="info-block">
        <nav aria-label="...">
          <ul class="pagination pagination-primary">
            <li class="page-item disabled">
              <a class="page-link" href="javascript:void(0)" tabindex="-1">Previous</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">1</a>
            </li>
            <li class="page-item active">
              <a class="page-link" href="javascript:void(0)">2 <span class="sr-only"> </span> </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="col-xl-6 xl-100 box-col-12">
      <div class="row search-vid-block">
        <div class="col-xl-12 xl-50 box-col-6">
          <div class="embed-responsive embed-responsive-21by9 lg-mt">
            <iframe class="w-100" height="315" src="https://www.youtube.com/embed/wpmHZspl4EM" allowfullscreen="">
            </iframe>
          </div>
        </div>
        <div class="col-xl-12 xl-50 box-col-6">
          <div class="embed-responsive embed-responsive-21by9">
            <iframe class="w-100" height="315" src="https://www.youtube.com/embed/I0-vBdh4sZ8" allowfullscreen="">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
