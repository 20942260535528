<template>
  <Breadcrumbs main="UI Kits" title="popover" />
  <div class="container-fluid">
    <div class="row popover-main">
      <basicExamples />
    </div>
  </div>
</template>
<script>
import basicExamples from "./basicExamples.vue";
export default {
  components: {
    basicExamples,
  },
};
</script>
