<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="table-responsive mt-3">
            <table class="table table-striped table-hover table-bordered" id="tabledt">
              <thead>
                <tr>
                  <th scope="col">Code</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Deskripsi</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>

                  <td>
                    <ul>
                      <li class="mb-1 mx-1">
                        <router-link to="/promotion/add" @click="deleteRow(item)">
                          <vue-feather class="btn btn-xs btn-outline-primary" size="18" type="trash"> </vue-feather>
                        </router-link>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <DeleteBank />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import DeleteBank from "@/components/bank/bank_delete.vue";

export default {
  components: {
    VueFeather,
    DeleteBank,
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      distributorIds: [],
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      valueDistributor: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      dataDistributor: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
    };
  },

  mounted() {
    this.user = localStorage.getItem("user");
    this.getdata();
    this.$store.state.getDataProduct = this.getdata;
  },
  methods: {
    async getdata() {
      this.items = this.$store.state.Products;
    },
    getRow() {
      var grid = document.getElementById("tabledt");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let data = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].checked) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
    },

    filter() {
      let params = {
        distributorIds: this.valueDistributor,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get(
          "/admin-api/promotion/list-promotions?perPage=" +
            this.perPage +
            "&" +
            valTag[0] +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.promotions.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.promotions;
          this.filteritems = this.items;
          this.meta = x.data.data.meta;
          this.filterOpt = true;
        });
    },

    reset() {
      this.valueTag = [];
      this.filterPublik = "";
      this.filterStatus = "";
      this.filter();
      this.filterOpt = false;
    },

    cari() {
      axios
        .get(
          "/admin-api/promotion/list-promotions?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.banners.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.banners;
          this.meta = x.data.data.meta;
        });
    },

    changePage(x) {
      this.page = x;
      axios
        .get("/admin-api/promotion/list-promotions?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.promotions.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.promotions;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
        });
    },

    showper() {
      this.isload = true;
      this.NoPage = 1;
      this.page = 1;
      axios
        .get(
          "/admin-api/banner/list-bank?page=" + this.page + "&perPage=" + this.perPage + "&search=" + this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.promotions.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.promotions;
          this.meta = x.data.data.meta;
          this.isload = false;
        });
    },

    editRow(x) {
      this.$store.state.dataBank = x;
    },

    deleteRow(x) {
      this.items = this.items.filter((c) => c.id != x.id);
    },

    setImagePreview(x) {
      this.$store.state.ImageBanner = x.image;
      this.$store.state.setImage();
    },

    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.valueTag.length >= 1) {
          for (let i = 0; i < this.valueTag.length; i++) {
            if (this.valueTag[i] === val) {
              return false;
            }
          }
        }
        this.valueTag.push(val);
        event.target.value = "";
        this.textTag = false;
      }
    },
    removeTag(index) {
      this.valueTag.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.valueTag.length - 1);
      }
    },
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
}

.active-page:hover {
  background-color: darkred;
}
</style>
