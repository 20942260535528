<template>
  <div class="col-xl-6 box-col-6">
    <div class="card target-sec">
      <div class="card-header pb-0">
        <ul class="target-list">
          <li>
            <h6>Our Target</h6>
            <p>Completed</p>
            <span>$687.780</span>
          </li>
          <li class="bg-primary">
            <h6>We Archieve</h6>
            <p>Completed in After 3 Hours</p>
            <span>$687.780k </span>
          </li>
        </ul>
      </div>
      <div class="card-body p-0">
        <div class="traget-img-sec"></div>
        <div class="animat-block">
          <i class="fa fa-times close1"> </i>
          <i class="fa fa-times close2"> </i>
          <i class="fa fa-times close3"> </i>
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
export default {
  components: {
    VueFeather,
  },
};
</script>
