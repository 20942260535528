<template>
  <div class="loader-wrapper" v-if="show">
    <div class="theme-loader">
      <div class="loader-p"></div>
    </div>
  </div>
  <Breadcrumbs main="Product" title="Add Product" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">
              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Code Product</label>
                <div class="col-sm-9">
                  <input v-model="codeProduct" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Nama Product</label>
                <div class="col-sm-9">
                  <input v-model="namaProduct" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Brand</label>
                <div class="col-sm-9">
                  <multiselect
                    v-model="brandProduct"
                    id="ajax"
                    label="name"
                    track-by="name"
                    open-direction="bottom"
                    :options="dataBrand"
                    :multiple="false"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="false"
                    :options-limit="300"
                    :limit="3"
                    placeholder=""
                    :max-height="600"
                    @search-change="asyncBrandSearch"
                  >
                    <span slot="noResult">Tidak Menemukan Kata kunci.</span>
                  </multiselect>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">UOM</label>
                <div class="col-sm-9">
                  <multiselect
                    v-model="uomProduct"
                    id="ajax"
                    label="name"
                    track-by="name"
                    open-direction="bottom"
                    :options="dataUOM"
                    :multiple="false"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="false"
                    :options-limit="300"
                    :limit="3"
                    placeholder=""
                    :max-height="600"
                    :hide-selected="false"
                    @search-change="asyncUOMSearch"
                  >
                    <span slot="noResult">Tidak Menemukan Kata kunci.</span>
                  </multiselect>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Category</label>
                <div class="col-sm-9">
                  <multiselect
                    v-model="categoryProduct"
                    id="ajax"
                    label="name"
                    track-by="name"
                    open-direction="bottom"
                    :options="dataCategory"
                    :multiple="false"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="false"
                    :options-limit="300"
                    :limit="3"
                    placeholder=""
                    :max-height="600"
                    :hide-selected="false"
                    @search-change="asyncCategorySearch"
                  >
                    <span slot="noResult">Tidak Menemukan Kata kunci.</span>
                  </multiselect>
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Berat</label>
                <div class="col-sm-9">
                  <input v-model="beratProduct" class="form-control" type="text" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Gambar</label>
                <div class="col-sm-9">
                  <input class="form-control" type="file" id="upcover" />
                </div>
              </div>

              <div class="row mb-4 g-3">
                <label class="col-sm-3 col-form-label text-start">Deskripsi</label>
                <div class="col-sm-9">
                  <input v-model="deskripsiProduct" class="form-control" type="text" />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3">
                  <div class="mb-3">
                    <div class="media row">
                      <label class="col-form-label m-r-10">Is Active</label>
                      <div class="media-body icon-state switch-outline">
                        <label class="switch">
                          <input type="checkbox" v-model="selectActive" />
                          <span class="switch-state bg-success"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="text-end">
                    <button @click="addItem" class="btn btn-sm btn-outline-success btn-lg me-4" type="button">
                      Simpan
                    </button>
                    <router-link to="/Product/list">
                      <button class="btn btn-sm btn-outline-primary btn-lg" type="button">Batal</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import Multiselect from "vue-multiselect";

export default {
  components: {
    VueFeather,
    Multiselect,
  },
  data() {
    return {
      user: [],
      meta: [],
      isLoading: false,
      show: false,
      brandProduct: null,
      uomProduct: null,
      categoryProduct: null,
      gambarProduct: null,
      selectedProduct: [],
      statusProduct: true,
      namaProduct: "",
      codeProduct: "",
      namaProduct: "",
      coverImage: "",
      beratProduct: "",
      dataDistributor: [],
      dataBrand: [],
      dataUOM: [],
      dataCategory: [],
      selectActive: true,
      base64Cover: null,
    };
  },
  mounted() {
    this.user = localStorage.getItem("token");
    this.getBrand();
    this.getUOM();
    this.getCategory();
  },

  methods: {
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    async addImage() {
      let img = await this.convertFileToBase64(document.getElementById("upcover").files[0]);
      var dataup = new FormData();
      dataup.append("file", img);

      const data = await fetch("https://files.forca.id/api/file/upload", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer 16dd3ccc-8c63-42b2-b81b-c30a9c4ce015",
        },
        body: dataup,
      });
      const content = await data.json();
      return content;
    },

    selectProduct(o, i) {
      this.logoProduct = o.url;
    },

    async addItem() {
      this.show = true;
      this.gambarProduct = await this.addImage();
      let data = {
        code: this.codeProduct,
        name: this.namaProduct,
        brandId: this.brandProduct.id,
        uomId: this.uomProduct.id,
        categoryId: this.categoryProduct.id,
        weight: this.beratProduct,
        image: this.gambarProduct.data[0].url,
        description: this.deskripsiProduct,
        isActive: this.selectActive,
      };
      axios
        .post("/admin-api/product/add-product", data, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          if (x) {
            this.show = false;
            this.$router.replace("/product/list");
            this.$toast.show(" Product ditambahkan ", {
              theme: "outline",
              position: "top-right",
              type: "success",
              duration: 5000,
            });
          }
        });
    },

    getBrand() {
      axios
        .get("/admin-api/product-brand/list-product-brands", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataBrand = x.data.data.productBrands;
        });
    },

    asyncBrandSearch(q) {
      this.isLoading = true;
      axios
        .get("/admin-api/product-brand/list-product-brands?search=" + q, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataBrand = x.data.data.productBrands;
          this.isLoading = false;
        });
    },

    getUOM() {
      axios
        .get("/admin-api/product-uom/list-product-uoms", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataUOM = x.data.data.productUoms;
        });
    },

    asyncUOMSearch(q) {
      this.isLoading = true;
      axios
        .get("/admin-api/product-uom/list-product-uoms?search=" + q, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataUOM = x.data.data.productUoms;
          this.isLoading = false;
        });
    },

    getCategory() {
      axios
        .get("/admin-api/product-category/list-product-categories", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataCategory = x.data.data.productCategories;
        });
    },

    asyncCategorySearch(q) {
      this.isLoading = true;
      axios
        .get("/admin-api/product-category/list-product-categories?search=" + q, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataCategory = x.data.data.productCategories;
          this.isLoading = false;
        });
    },

    selectAdd(e) {
      let val = e.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
      }
    },

    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.bannerTags.length >= 1) {
          for (let i = 0; i < this.bannerTags.length; i++) {
            if (this.bannerTags[i] === val) {
              return false;
            }
          }
        }
        this.bannerTags.push(val);
        event.target.value = "";
      }
    },

    removeTag(index) {
      this.bannerTags.splice(index, 1);
    },

    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.bannerTags.length - 1);
      }
    },
  },
};
</script>
<style>
/* @import 'dropzone-vue/dist/dropzone-vue.common.css'; */
.ck-editor__editable {
  min-height: 200px;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 40px;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 5px;
}

.tag-input__tag {
  height: 20px;
  color: white;
  float: left;
  font-size: 10px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 0px;
  line-height: 20px;
  padding: 0 8px;
  /* font-family: "Roboto"; */
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}
</style>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
