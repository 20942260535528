<template>
  <div class="col-sm-12 col-xl-12">
    <div class="card card-overflow">
      <div class="card-header pb-0">
        <h5>Basic Dropdown</h5>
      </div>
      <div class="card-body dropdown-basic">
        <div class="dropdown">
          <button class="dropbtn btn-primary" type="button">
            Dropdown Button
            <span>
              <i class="icofont icofont-arrow-down"> </i>
            </span>
          </button>
          <div class="dropdown-content">
            <a href="#">Action</a>
            <a href="#">Another Action</a>
            <a href="#">Something Else Here</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
