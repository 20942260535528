<template>
  <div class="col-sm-12 col-xl-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>With Icon</h5>
      </div>
      <div class="card-body">
        <div class="list-group">
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <i class="icon-save-alt"> </i>
            Cras justo odio
          </a>
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <i class="icon-target"> </i>
            Dapibus ac facilisis in
          </a>
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <i class="icon-mobile"> </i>
            Morbi leo risus
          </a>
          <a class="list-group-item list-group-item-action disabled" href="javascript:void(0)">
            <i class="icon-shopping-cart-full"> </i>
            Vestibulum at eros
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>With Images</h5>
      </div>
      <div class="card-body">
        <div class="list-group">
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <img src="../../../assets/images/user/1.jpg" alt="" />
            Cras justo odio
          </a>
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <img src="../../../assets/images/user/1.jpg" alt="" />
            Dapibus ac facilisis
          </a>
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <img src="../../../assets/images/user/1.jpg" alt="" />
            Morbi leo risus
          </a>
          <a class="list-group-item list-group-item-action disabled" href="javascript:void(0)">
            <img src="../../../assets/images/user/1.jpg" alt="" />
            Vestibulum at eros
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>With Image Rounded</h5>
      </div>
      <div class="card-body">
        <div class="list-group">
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <img class="rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
            Cras justo odio
          </a>
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <img class="rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
            Dapibus ac facilisis
          </a>
          <a class="list-group-item list-group-item-action" href="javascript:void(0)">
            <img class="rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
            Morbi leo risus
          </a>
          <a class="list-group-item list-group-item-action disabled" href="javascript:void(0)">
            <img class="rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
            Vestibulum at eros
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
