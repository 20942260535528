<template>
  <div class="col-sm-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Shape</h5>
      </div>
      <div class="card-body avatar-showcase">
        <div class="avatars">
          <div class="avatar">
            <img class="img-100 b-r-8" src="../../../assets/images/user/1.jpg" alt="#" />
          </div>
          <div class="avatar">
            <img class="img-90 b-r-30" src="../../../assets/images/user/1.jpg" alt="#" />
          </div>
          <div class="avatar">
            <img class="img-80 b-r-35" src="../../../assets/images/user/1.jpg" alt="#" />
          </div>
          <div class="avatar">
            <img class="img-70 rounded-circle" src="../../../assets/images/user/1.jpg" alt="#" />
          </div>
          <div class="avatar">
            <img class="img-60 b-r-25" src="../../../assets/images/user/1.jpg" alt="#" />
          </div>
          <div class="avatar">
            <img class="img-50 b-r-15" src="../../../assets/images/user/1.jpg" alt="#" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Groups</h5>
      </div>
      <div class="card-body avatar-showcase">
        <div class="customers d-inline-block avatar-group">
          <ul>
            <li class="d-inline-block">
              <img class="img-70 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" />
            </li>
            <li class="d-inline-block">
              <img class="img-70 rounded-circle" src="../../../assets/images/user/5.jpg" alt="" />
            </li>
            <li class="d-inline-block">
              <img class="img-70 rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
            </li>
          </ul>
        </div>
        <div class="customers d-inline-block avatar-group">
          <ul>
            <li class="d-inline-block">
              <img class="img-50 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" />
            </li>
            <li class="d-inline-block">
              <img class="img-50 rounded-circle" src="../../../assets/images/user/5.jpg" alt="" />
            </li>
            <li class="d-inline-block">
              <img class="img-50 rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
            </li>
          </ul>
        </div>
        <div class="customers d-inline-block avatar-group">
          <ul>
            <li class="d-inline-block">
              <img class="img-40 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" />
            </li>
            <li class="d-inline-block">
              <img class="img-40 rounded-circle" src="../../../assets/images/user/5.jpg" alt="" />
            </li>
            <li class="d-inline-block">
              <img class="img-40 rounded-circle" src="../../../assets/images/user/1.jpg" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
