<template>
  <div class="col-xl-6 col-lg-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Text Color</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .font-primary {
            color: #ab8ce4 !important;
          }
          .font-secondary {
            color: #26c6da !important;
          }
          .font-success {
            color: #00c292 !important;
          }
          .font-danger {
            color: #FF5370 !important;
          }
          .font-info {
            color: #4099ff !important;
          }
          .font-light {
            color: #eeeeee !important;
          }
          .font-dark {
            color: #2a3142 !important;
          }
          .font-warning {
            color: #f3d800 !important;
          }
        </pre>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-lg-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Badge Color</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .badge-primary {
            background-color: #ab8ce4;
          }
          .badge-secondary {
            background-color: #26c6da;
          }
          .badge-success {
            background-color: #00c292;
          }
          .badge-danger {
            background-color: #FF5370;
          }
          .badge-info {
            background-color: #4099ff;
          }
          .badge-light {
            background-color: #eeeeee;
          }
          .badge-dark {
            background-color: #2a3142;
          }
          .badge-warning {
            background-color: #f3d800;
          }
        </pre>
      </div>
    </div>
  </div>
</template>
