import Nested from "@/layout/Nested.vue";

import List from "@/components/partner/salesman/List.vue";
// import ListCustomer from "@/components/partner/salesman/ListCustomer.vue";
import ListSalesmanCustomer from "@/components/partner/salesman/ListSalesmanCustomer.vue";

const salesmanRoutes = [
  {
    path: "salesman",
    component: Nested,
    children: [
      {
        path: "",
        component: List,
      },
      // {
      //   path: "customer",
      //   component: ListCustomer,
      // },
    ],
  },
  {
    path: "salesman-customer",
    component: Nested,
    children: [
      {
        path: "",
        component: ListSalesmanCustomer,
      },
    ],
  },
];

export default salesmanRoutes;
