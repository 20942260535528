import { authHeader, jsonHeader, utils } from "@/_helpers";

export const salesmanService = { getAll, getCustomers };

async function getAll(params) {
  const url = `${process.env.VUE_APP_API_URL}/admin-api/salesman/list-salesmans`;
  const options = { method: "get" };
  const config = {
    headers: { ...jsonHeader, ...authHeader() },
    params,
  };
  const { status, data } = await utils.reqres(url, options, config);

  if (status === 401) {
    utils.removeAuthToken();
    return location.reload(true);
  }

  if (data.error) return console.error(data.message);
  return data.data;
}

async function getCustomers(params) {
  const url = `${process.env.VUE_APP_API_URL}/admin-api/salesman/list-customer-salesmans`;
  const options = { method: "get" };
  const config = {
    headers: { ...jsonHeader, ...authHeader() },
    params,
  };
  const { status, data } = await utils.reqres(url, options, config);

  if (status === 401) {
    utils.removeAuthToken();
    return location.reload(true);
  }

  if (data.error) return console.error(data.message);
  return data.data;
}
