const menu = [
  {
    headTitle1: "Administrator Tools",
    type: "headtitle",
  },
  {
    title: "Sync Master Data",
    icon: "refresh-cw",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/data_sync/master",
  },
];

export default menu;
