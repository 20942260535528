import axios from "axios";

function reqres(url, options, config) {
  const { method, payload } = options;

  if (["post", "put", "patch"].includes(method)) return axios[method](url, payload || {}, config);
  return axios[method](url, config);
}

function removeAuthToken() {
  localStorage.removeItem("token");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        removeAuthToken();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export const utils = { reqres, removeAuthToken, handleResponse };
