<template>
  <div class="col-xl-4">
    <div class="row">
      <div class="col-xl-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <i class="m-r-30" data-feather="youtube">
                <vue-feather type="youtube"> </vue-feather>
              </i>
              <div class="media-body">
                <h6 class="f-w-600">sources credible/reliable</h6>
                <p>simple demos of frequently asked questions about using the Libraries and information resources</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <i class="m-r-30" data-feather="file-text">
                <vue-feather type="file-text"> </vue-feather>
              </i>
              <div class="media-body">
                <h6 class="f-w-600">knows your sources</h6>
                <p>A book giving information on many subjects or on many aspects of one subject.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <i class="m-r-30" data-feather="file-text">
                <vue-feather type="file-text"> </vue-feather>
              </i>
              <div class="media-body">
                <h6 class="f-w-600">Web Design</h6>
                <p>Web is so high-level, it never encourages you to design the same site twice.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
