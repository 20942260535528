<template>
  <Breadcrumbs main="Data Sync" title="Sync Master Data" />
  <div class="container-fluid">
    <LoadingModal :show="isLoadingModal" />
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered" id="tabledt">
              <thead>
                <tr>
                  <th scope="col" class="text-center">No</th>
                  <th scope="col">Tipe Sinkron</th>
                  <th scope="col">Parameter</th>
                  <th scope="col">Terakhir Sinkron</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td class="text-center">{{ item.no }}</td>
                  <td>{{ item.sync_type }}</td>
                  <td>{{ item.parameter }}</td>
                  <td>{{ item.sync_log ? item.sync_log.last_synced_at : '-' }}</td>
                  <td>
                    <vue-feather
                      class="btn btn-xs btn-primary" size="18" type="refresh-cw"
                      @click="syncMaster(item)">
                    </vue-feather>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- row and pagination  -->
          <div class="flex">
            <div class="d-flex justify-content-end mb-2">
              <div class="mt-4 mx-3">
                <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
              </div>

              <!-- pagination  -->
              <div class="mt-4 mx-3">
                <vue-awesome-paginate
                  v-show="items.length > 0 && isload == false"
                  :total-items="meta.total"
                  :items-per-page="meta.perPage"
                  :max-pages-shown="meta.perPage"
                  v-model="page"
                  @click="changePage"
                />
              </div>

              <div class="mt-n1 mx-4">
                <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                  <p>
                    <small>Tampilkan</small>
                    <select
                      name="basic-1_length"
                      aria-controls="basic-1"
                      class="form-control"
                      @change="showper"
                      v-model="perPage"
                    >
                      <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of row and pagination  -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <DeleteBank />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import DeleteBank from "@/components/bank/bank_delete.vue";
import LoadingModal from './modal_loading.vue';

export default {
  components: {
    VueFeather,
    DeleteBank,
    LoadingModal
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      distributorIds: [],
      isLoading: false,
      isLoadingModal: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      valueDistributor: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      dataDistributor: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
    };
  },

  mounted() {
    this.user = localStorage.getItem("user");
    this.getdata();
    this.$store.state.getdataBank = this.getdata;
  },
  methods: {
    getdata() {
      axios
        .get("/admin-api/sync/logs?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.adminSyncType.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.adminSyncType;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
        });
    },
    getRow() {
      var grid = document.getElementById("tabledt");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let data = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].checked) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
    },

    // cari() {
    //   axios
    //     .get(
    //       "/admin-api/bank/list-banks?page=" + this.page + "&perPage=" + this.perPage + "&search=" + this.filterQuery,
    //       { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    //     )
    //     .then((x) => {
    //       let num = this.perPage * this.page;
    //       this.firstNo = num - this.perPage + 1;
    //       this.No = this.page * this.perPage;
    //       this.No = this.No - this.perPage;
    //       x.data.data.adminSyncType.forEach((z) => {
    //         this.No++;
    //         z.no = this.No;
    //       });
    //       this.lastNo = this.No;
    //       this.items = x.data.data.adminSyncType;
    //       this.meta = x.data.data.meta;
    //     });
    // },

    changePage(x) {
      this.page = x;
      axios
        .get("/admin-api/sync/logs?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.adminSyncType.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.adminSyncType;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
        });
    },

    syncMaster(item) {
      // console.log(item.code);
      this.isLoadingModal = true;

      var params = "id="+item.id+"&parameter="+item.parameter+"&token="+item.token;
      axios
        .get(
          "/admin-api/sync/execute?"+params,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((response) => {
          if (response) {
            this.$toast.show(response.data.message, {
              theme: "outline",
              position: "top-right",
              type: "success",
              duration: 5000,
            });
            this.isLoadingModal = false;
            this.getdata();
          }
        });
    }
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
}

.active-page:hover {
  background-color: darkred;
}
</style>
