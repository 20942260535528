<template>
  <div class="col-sm-12 col-xl-12 col-xxl-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Default Style</h5>
        <span>This option is show buydefault you don't need to add any extra class</span>
      </div>
      <div class="card-body megaoptions-border-space-sm">
        <form class="mega-inline">
          <div class="row">
            <div class="col-sm-6">
              <div class="card">
                <div class="media p-20">
                  <div class="radio radio-primary me-3">
                    <input id="radio14" type="radio" name="radio1" value="1" v-model="defaultStyle" />
                    <label for="radio14"> </label>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mega-title-badge">
                      COD<span class="badge badge-primary pull-right digits">50 INR</span>
                    </h6>
                    <p>Estimated 1 Day Shipping (Duties end tax may be due delivery )</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="media p-20">
                  <div class="radio radio-secondary me-3">
                    <input id="radio13" type="radio" name="radio1" value="2" v-model="defaultStyle" />
                    <label for="radio13"> </label>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mega-title-badge">
                      Fast<span class="badge badge-secondary pull-right digits">100 INR</span>
                    </h6>
                    <p>Estimated 1 Day Shipping (Duties end tax may be due delivery )</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer text-end">
        <button class="btn btn-primary m-r-15" type="submit">Submit</button>
        <button class="btn btn-light" type="submit">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultStyle: 1,
    };
  },
};
</script>
