<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Pricing</h5>
      </div>
      <div class="card-body row pricing-content pricing-col">
        <div class="col-md-3">
          <div class="pricing-block card text-center">
            <div class="pricing-header">
              <h2>standard</h2>
              <div class="price-box">
                <div>
                  <h3>$10</h3>
                  <p>/ month</p>
                </div>
              </div>
            </div>
            <div class="pricing-list">
              <ul class="pricing-inner">
                <li>
                  <h6>50GB<span> Disk Space</span></h6>
                </li>
                <li>
                  <h6>50<span> Email Accounts</span></h6>
                </li>
                <li>
                  <h6>50GB<span> Bandwidth</span></h6>
                </li>
                <li>
                  <h6>10<span> Subdomains</span></h6>
                </li>
                <li>
                  <h6>15<span> Domains</span></h6>
                </li>
              </ul>
              <button
                class="btn btn-primary btn-lg"
                type="button"
                data-original-title="btn btn-primary btn-lg"
                title=""
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="pricing-block card text-center">
            <div class="pricing-header">
              <h2>business</h2>
              <div class="price-box">
                <div>
                  <h3>$20</h3>
                  <p>/ month</p>
                </div>
              </div>
            </div>
            <div class="pricing-list">
              <ul class="pricing-inner">
                <li>
                  <h6>60GB<span> Disk Space</span></h6>
                </li>
                <li>
                  <h6>60<span> Email Accounts</span></h6>
                </li>
                <li>
                  <h6>60GB<span> Bandwidth</span></h6>
                </li>
                <li>
                  <h6>15<span> Subdomains</span></h6>
                </li>
                <li>
                  <h6>20<span> Domains</span></h6>
                </li>
              </ul>
              <button
                class="btn btn-primary btn-lg"
                type="button"
                data-original-title="btn btn-primary btn-lg"
                title=""
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="pricing-block card text-center">
            <div class="pricing-header">
              <h2>PREMIUM</h2>
              <div class="price-box">
                <div>
                  <h3>$30</h3>
                  <p>/ month</p>
                </div>
              </div>
            </div>
            <div class="pricing-list">
              <ul class="pricing-inner">
                <li>
                  <h6>70GB<span> Disk Space</span></h6>
                </li>
                <li>
                  <h6>70<span> Email Accounts</span></h6>
                </li>
                <li>
                  <h6>70GB<span> Bandwidth</span></h6>
                </li>
                <li>
                  <h6>25<span> Subdomains</span></h6>
                </li>
                <li>
                  <h6>30<span> Domains</span></h6>
                </li>
              </ul>
              <button
                class="btn btn-primary btn-lg"
                type="button"
                data-original-title="btn btn-primary btn-lg"
                title=""
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="pricing-block card text-center">
            <div class="pricing-header">
              <h2>auther pack</h2>
              <div class="price-box">
                <div>
                  <h3>$40</h3>
                  <p>/ month</p>
                </div>
              </div>
            </div>
            <div class="pricing-list">
              <ul class="pricing-inner">
                <li>
                  <h6>80GB<span> Disk Space</span></h6>
                </li>
                <li>
                  <h6>80<span> Email Accounts</span></h6>
                </li>
                <li>
                  <h6>80GB<span> Bandwidth</span></h6>
                </li>
                <li>
                  <h6>25<span> Subdomains</span></h6>
                </li>
                <li>
                  <h6>30<span> Domains</span></h6>
                </li>
              </ul>
              <button
                class="btn btn-primary btn-lg"
                type="button"
                data-original-title="btn btn-primary btn-lg"
                title=""
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
