<template>
  <div class="col-sm-12 col-xl-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Progress Bars Striped</h5>
        <span>
          Add <code>.progress-bar-striped</code> to any <code>.progress-bar</code> to apply a stripe via CSS gradient
          over the progress bar’s background color.
        </span>
      </div>
      <div class="card-body progress-showcase row">
        <div class="col">
          <div class="progress">
            <div
              class="progress-bar bg-primary progress-bar-striped"
              role="progressbar"
              style="width: 10%"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped bg-secondary"
              role="progressbar"
              style="width: 25%"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              style="width: 50%"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped bg-info"
              role="progressbar"
              style="width: 75%"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
