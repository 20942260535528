<template>
  <div class="col-xl-4 col-md-6 box-col-4">
    <div>
      <div class="card card-activity">
        <div class="card-header pb-0 d-flex justify-content-between align-items-center">
          <h5 class="text-uppercase">Recent Activity</h5>
        </div>
        <div class="card-body">
          <ul class="crm-activity">
            <li class="media">
              <span class="me-3 font-primary">A</span>
              <div class="align-self-center media-body">
                <h6 class="mt-0">Any desktop publishing.</h6>
                <ul class="dates">
                  <li>25 July 2022</li>
                  <li>20 hours ago</li>
                </ul>
              </div>
            </li>
            <li class="media">
              <span class="me-3 font-secondary">C</span>
              <div class="align-self-center media-body">
                <h6 class="mt-0">Contrary to popular belief.</h6>
                <ul class="dates">
                  <li>25 July 2022</li>
                  <li>20 hours ago</li>
                </ul>
              </div>
            </li>
            <li class="media">
              <span class="me-3 font-primary">E</span>
              <div class="align-self-center media-body">
                <h6 class="mt-0">Established fact that a reader.</h6>
                <ul class="dates">
                  <li>25 July 2022</li>
                  <li>20 hours ago</li>
                </ul>
              </div>
            </li>
            <li class="media">
              <span class="me-3 font-secondary">H</span>
              <div class="align-self-center media-body">
                <h6 class="mt-0">H-Code - A premium portfolio.</h6>
                <ul class="dates">
                  <li>25 July 2022</li>
                  <li>20 hours ago</li>
                </ul>
              </div>
            </li>
            <li class="media">
              <span class="me-3 font-primary">T</span>
              <div class="align-self-center media-body">
                <h6 class="mt-0">There isn't any hidden.</h6>
                <ul class="dates">
                  <li>25 July 2022</li>
                  <li>20 hours ago</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
