<template>
  <div class="col-xl-6 col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Font Size</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .f-12 {
            font-size: 12px;
          }
          .f-14 {
            font-size: 14px;
          }
          .f-16 {
            font-size: 16px;
          }
          .f-18 {
            font-size: 18px;
          }
          .f-20 {
            font-size: 20px;
          }
          .f-22 {
            font-size: 22px;
          }
          .f-24 {
            font-size: 24px;
          }
          .f-26 {
            font-size: 26px;
          }
          .f-28 {
            font-size: 28px;
          }
          .f-30 {
            font-size: 30px;
          }
          .f-32 {
            font-size: 32px;
          }
          .f-34 {
            font-size: 34px;
          }
          .f-36 {
            font-size: 36px;
          }
          .f-38 {
            font-size: 38px;
          }
          .f-40 {
            font-size: 40px;
          }
          .f-42 {
            font-size: 42px;
          }
          .f-44 {
            font-size: 44px;
          }
          .f-46 {
            font-size: 46px;
          }
          .f-48 {
            font-size: 48px;
          }
          .f-50 {
            font-size: 50px;
          }
          .f-52 {
            font-size: 52px;
          }
          .f-54 {
            font-size: 54px;
          }
          .f-56 {
            font-size: 56px;
          }
          .f-58 {
            font-size: 58px;
          }
          .f-60 {
            font-size: 60px;
          }
          .f-62 {
            font-size: 62px;
          }
          .f-64 {
            font-size: 64px;
          }
          .f-66 {
            font-size: 66px;
          }
          .f-68 {
            font-size: 68px;
          }
          .f-70 {
            font-size: 70px;
          }
          .f-72 {
            font-size: 72px;
          }
          .f-74 {
            font-size: 74px;
          }
          .f-76 {
            font-size: 76px;
          }
          .f-78 {
            font-size: 78px;
          }
          .f-80 {
            font-size: 80px;
          }
          .f-82 {
            font-size: 82px;
          }
          .f-84 {
            font-size: 84px;
          }
          .f-86 {
            font-size: 86px;
          }
          .f-88 {
            font-size: 88px;
          }
          .f-90 {
            font-size: 90px;
          }
          .f-92 {
            font-size: 92px;
          }
          .f-94 {
            font-size: 94px;
          }
          .f-96 {
            font-size: 96px;
          }
          .f-98 {
            font-size: 98px;
          }
          .f-100 {
            font-size: 100px;
          }
        </pre>
      </div>
    </div>
  </div>
</template>
