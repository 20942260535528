<template>
  <div class="col-sm-12 col-xl-4">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Switch With icon</h5>
      </div>
      <div class="card-body row switch-showcase">
        <div class="col-sm-12">
          <div class="media">
            <label class="col-form-label m-r-10">Large Size</label>
            <div class="media-body text-end switch-lg icon-state">
              <label class="switch"> <input type="checkbox" checked="" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Large Unhecked</label>
            <div class="media-body text-end switch-lg icon-state">
              <label class="switch"> <input type="checkbox" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Normal Size</label>
            <div class="media-body text-end icon-state">
              <label class="switch"> <input type="checkbox" checked="" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Normal Unhecked</label>
            <div class="media-body text-end icon-state">
              <label class="switch"> <input type="checkbox" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Small Size</label>
            <div class="media-body text-end switch-sm icon-state">
              <label class="switch"> <input type="checkbox" checked="" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Small Size Unhecked</label>
            <div class="media-body text-end switch-sm icon-state">
              <label class="switch"> <input type="checkbox" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
