import support from "../../data/support.json";

const state = {
  items: support.items,
};

export default {
  namespaced: true,
  state,
};
