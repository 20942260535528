<template>
  <div class="col-xl-6 col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Background Color</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .bg-primary {
            background-color: #ab8ce4 !important;
            color: #fff;
          }
          .bg-secondary {
            background-color: #26c6da !important;
            color: #fff;
          }
          .bg-success {
            background-color: #00c292 !important;
            color: #fff;
          }
          .bg-danger {
            background-color: #FF5370 !important;
            color: #fff;
          }
          .bg-info {
            background-color: #4099ff !important;
            color: #fff;
          }
          .bg-light {
            background-color: #eeeeee !important;
            color: #fff;
          }
          .bg-dark {
            background-color: #2a3142 !important;
            color: #fff;
          }
          .bg-warning {
            background-color: #f3d800 !important;
            color: #fff;
          }
        </pre>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Button Color</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .btn-primary {
            background-color: #ab8ce4;
            border-color: #ab8ce4;
          }
          .btn-secondary {
            background-color: #26c6da;
            border-color: #26c6da;
          }
          .btn-success {
            background-color: #00c292 !important;
            color: #fff;
          }
          .btn-success {
            background-color: #00c292;
            border-color: #00c292;
          }
          .btn-danger {
            background-color: #FF5370;
            border-color: #FF5370;
          }
          .btn-info {
            background-color: #4099ff;
            border-color: #4099ff;
          }
          .btn-light {
            background-color: #eeeeee;
            border-color: #eeeeee;
          }
          .btn-warning {
            background-color: #f3d800;
            border-color: #f3d800;
          }
        </pre>
      </div>
    </div>
  </div>
</template>
