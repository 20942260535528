<template>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 13</h6>
    <div class="loader-box">
      <div class="loader-13"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 14</h6>
    <div class="loader-box">
      <div class="loader-14"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 15</h6>
    <div class="loader-box">
      <div class="loader-15"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 16</h6>
    <div class="loader-box">
      <div class="loader-40"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 17</h6>
    <div class="loader-box">
      <div class="loader-17"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 18</h6>
    <div class="loader-box">
      <div class="loader-18"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 19</h6>
    <div class="loader-box">
      <div class="loader-19"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 20</h6>
    <div class="loader-box">
      <div class="loader-20"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 21</h6>
    <div class="loader-box">
      <div class="loader-21"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 22</h6>
    <div class="loader-box">
      <div class="loader-22"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 23</h6>
    <div class="loader-box">
      <div class="loader-23"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 24</h6>
    <div class="loader-box">
      <div class="loader-24"></div>
    </div>
  </div>
</template>
