<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5>Custom Table Color with Hover and Stripped</h5>
        <span>
          Use class<code>table-hover, table-striped table-*</code><code>table-info</code>,<code>table-success</code>,
          <code>table-success</code>, <code>table-info</code>, <code>table-danger</code>,<code>table-primary</code>,
          <code>table-secondary</code>,<code>table-light</code>,<code>table-active</code>inside table element.
        </span>
      </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive table-border-radius">
            <table class="table table-styling table-hover table-striped table-primary">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Username</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items3: [
        { no: 1, firstname: "Mark", lastname: "Otto", username: "@mdo" },
        { no: 2, firstname: "Jacob", lastname: "Thornton", username: "@fat" },
        { no: 3, firstname: "Larry", lastname: "the Bird", username: "@twitter" },
        { no: 3, firstname: "Larry", lastname: "the Bird", username: "@twitter" },
        { no: 3, firstname: "Larry", lastname: "the Bird", username: "@twitter" },
      ],
    };
  },
};
</script>
