<template>
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Pagination alignment</h5>
      </div>
      <div class="card-body pagination-padding">
        <nav class="m-b-30" aria-label="Page navigation example">
          <ul class="pagination pagination-primary">
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">Previous</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">Next</a>
            </li>
          </ul>
        </nav>
        <nav class="m-b-30" aria-label="Page navigation example">
          <ul class="pagination justify-content-center pagination-primary">
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">Previous</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">Next</a>
            </li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end pagination-primary">
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">Previous</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0)">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
