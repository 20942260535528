<template>
  <div class="col-sm-12 col-xl-12 col-xxl-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>No Border</h5>
        <span>By adding <code>.plain-style</code> class to <code>.mega-inline</code> You can archive this style</span>
      </div>
      <div class="card-body">
        <form class="mega-inline plain-style">
          <div class="row">
            <div class="col-sm-6">
              <div class="card">
                <div class="media p-20">
                  <div class="radio radio-primary me-3">
                    <input id="radio11" type="radio" name="radio1" value="1" v-model="noBorder" />
                    <label for="radio11"> </label>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mega-title-badge">
                      COD<span class="badge badge-primary pull-right digits">50 INR</span>
                    </h6>
                    <p>Estimated 1 Day Shipping (Duties end tax may be due delivery )</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="media p-20">
                  <div class="radio radio-secondary me-3">
                    <input id="radio12" type="radio" name="radio1" value="2" v-model="noBorder" />
                    <label for="radio12"> </label>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mega-title-badge">
                      Fast<span class="badge badge-secondary pull-right digits">100 INR</span>
                    </h6>
                    <p>Estimated 1 Day Shipping (Duties end tax may be due delivery )</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer text-end">
        <button class="btn btn-primary m-r-15" type="submit">Submit</button>
        <button class="btn btn-light" type="submit">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      noBorder: 1,
    };
  },
};
</script>
