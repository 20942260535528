<template>
  <div class="sidebar-user text-center">
    <a class="setting-primary" href="javascript:void(0)" @click="opensetting">
      <vue-feather type="settings"> </vue-feather>
    </a>
    <img class="img-90 rounded-circle" src="../../assets/images/dashboard/1.png" alt="" />
    <div class="badge-bottom">
      <span class="badge badge-primary">New</span>
    </div>

    <router-link to="/users/profile">
      <h6 class="mt-3 f-14 f-w-600">{{ Profile.firstName }}</h6>
    </router-link>
    <!-- <p class="mb-0 font-roboto">{{ 'Jabatan' }}</p> -->
    <!-- <ul>
      <li>
        <span class="counter">
          <number
            class="bold counter"
            ref="number1"
            :from="0"
            :to="19.8"
            :format="theFormat"
            :duration="5"
            :delay="0"
            easing="Power1.easeOut"
          />k
        </span>
        <p>Follow</p>
      </li>
      <li>
        <span class="count">2 year</span>
        <p>Experince</p>
      </li>
      <li>
        <span>
          <span class="counter">
            <number
              class="bold counter"
              ref="number1"
              :from="0"
              :to="95.2"
              :format="theFormat"
              :duration="5"
              :delay="0"
              easing="Power1.easeOut"
            /> </span
          >k</span
        >
        <p>Follower</p>
      </li>
    </ul> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      Profile: [],
    };
  },
  computed: {},
  created() {
    this.Profile = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    opensetting() {
      const { value: formValues } = this.$swal.fire({
        title: "Change Password",
        html:
          '<input id="swal-input1" type="password" placeholder="Current Password" class="swal2-input">' +
          '<input id="swal-input2" type="password" placeholder="Password" class="swal2-input">' +
          '<input id="swal-input3" type="password" placeholder="Retype New Password" class="swal2-input">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            this.changepassword(
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").value,
              document.getElementById("swal-input3").value
            ),
          ];
        },
      });
    },

    changepassword(cpass, pass, rpass) {
      let data = {
        currentPassword: cpass,
        newPassword: pass,
        retypeNewPassword: rpass,
      };
      return axios
        .post("/admin-api/account/change-password", data, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((x) => {
          this.$swal({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            type: "success",
            title: "Password Disimpan",
          });
        });
    },

    theFormat(number) {
      return number.toFixed(1);
    },
  },
};
</script>
