<template>
  <div class="col-sm-12 col-xl-6 box-col-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Radial Bar Chart</h5>
      </div>
      <div class="card-body">
        <div id="circlechart">
          <apexchart height="320" type="radialBar" :options="chartOptions" :series="series"> </apexchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var primary = localStorage.getItem("--theme-deafult") || "#24695c";
var secondary = localStorage.getItem("--theme-secondary") || "#ba895d";
export default {
  data() {
    return {
      series: [44, 55, 67, 83],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return 249;
                },
              },
            },
          },
        },

        labels: ["Apples", "Oranges", "Bananas", "Berries"],
        colors: [primary, secondary, "#222222", "#717171"],
      },
    };
  },
};
</script>
