<template>
  <div class="form theme-form">
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Project Title</label>
          <input class="form-control" type="text" placeholder="Project name *" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Client name</label>
          <input class="form-control" type="text" placeholder="Name client or company name" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="mb-3">
          <label>Project Rate</label>
          <input class="form-control" type="text" placeholder="Enter project Rate" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="mb-3">
          <label>Project Type</label>
          <select class="form-select">
            <option>Hourly</option>
            <option>Fix price</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="mb-3">
          <label>Priority</label>
          <select class="form-select">
            <option>Low</option>
            <option>Medium</option>
            <option>High</option>
            <option>Urgent</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="mb-3">
          <label>Project Size</label>
          <select class="form-select">
            <option>Small</option>
            <option>Medium</option>
            <option>Big</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="mb-3">
          <label>Starting date</label>
          <input class="datepicker-here form-control" type="date" data-language="en" />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="mb-3">
          <label>Ending date</label>
          <input class="datepicker-here form-control" type="date" data-language="en" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Enter some Details</label>
          <textarea class="form-control" id="exampleFormControlTextarea4" rows="3"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Upload project file</label>
          <DropZone :maxFileSize="Number(60000000)" :uploadOnDrop="true" :dropzoneMessageClassName="customClass">
          </DropZone>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="text-end">
          <a class="btn btn-secondary me-3" href="#">Add</a>
          <a class="btn btn-danger" href="#">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropZone from "dropzone-vue";
export default {
  components: {
    DropZone,
  },
};
</script>
<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
</style>
