import table from "../../data/table.json";

const state = {
  items: table.items,
};

export default {
  namespaced: true,
  state,
};
