<template>
  <div class="col-sm-12 col-lg-6">
    <div class="card height-equal">
      <div class="card-header pb-0">
        <h5>Outline Dark Alerts</h5>
        <span>Outline Archive just adding class <code>.outline-2x</code> </span>
      </div>
      <div class="card-body">
        <div class="alert alert-primary outline-2x" role="alert">
          <p>This is a primary alert—check it out!</p>
        </div>
        <div class="alert alert-secondary outline-2x" role="alert">
          <p>This is a secondary alert—check it out!</p>
        </div>
        <div class="alert alert-success outline-2x" role="alert">
          <p>This is a success alert—check it out!</p>
        </div>
        <div class="alert alert-info outline-2x" role="alert">
          <p>This is a info alert—check it out!</p>
        </div>
        <div class="alert alert-warning outline-2x" role="alert">
          <p>This is a warning alert—check it out!</p>
        </div>
        <div class="alert alert-danger outline-2x" role="alert">
          <p>This is a danger alert—check it out!</p>
        </div>
        <div class="alert alert-light outline-2x" role="alert">
          <p>This is a light alert—check it out!</p>
        </div>
        <div class="alert alert-dark outline-2x" role="alert">
          <p>This is a light alert—check it out!</p>
        </div>
      </div>
    </div>
  </div>
</template>
