import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import Breadcrumbs from "./layout/breadCrumbs.vue";
import VueApexCharts from "vue3-apexcharts";
import "v-calendar/dist/style.css";
import VCalendar from "v-calendar";
import VueKanban from "vue-kanban";
import Pxcard from "./layout/Pxcard";
import VueNumber from "vue-number-animation";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

import "./assets/scss/app.scss";
import VueFeather from "vue-feather";
import Lightbox from "vue-easy-lightbox";
import Notifications from "@kyvg/vue3-notification";
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import "form-wizard-vue3/dist/form-wizard-vue3.css";
import Wizard from "form-wizard-vue3";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Toaster from "@meforma/vue-toaster";
import { VueMasonryPlugin } from "vue-masonry";
import Vue3Tour from "vue3-tour";
import "vue3-tour/dist/vue3-tour.css";
import { quillEditor } from "vue3-quill";
import rate from "vue-rate";
import "vue-rate/dist/vue-rate.css";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import vueChartist from "vue-chartist";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { configureFakeBackend } from "./_helpers";
configureFakeBackend();

import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post["Access-Control-Allow-Origin"] = process.env.VUE_APP_API_URL;

createApp(App)
  .use(store)
  .use(router)
  .use(VueNumber)
  .use(vueChartist)
  .use(require("vue-chartist"))
  .use(VueApexCharts)
  .use(VueKanban)
  .component(VueFeather.name, VueFeather)
  .component(VueCountdown.name, VueCountdown)
  .component("Breadcrumbs", Breadcrumbs)
  .component("Datepicker", Datepicker)
  .component(Pxcard.name, Pxcard)
  .use(VCalendar, {})
  .use(Lightbox)
  .use(PerfectScrollbar)
  .use(VueSweetalert2)
  .use(Wizard)
  .use(rate)
  .use(Notifications)
  .use(SimpleTypeahead)
  .use(Toaster)
  .component("multiselect", Multiselect)
  .use(quillEditor)
  .use(require("vue-chartist"))
  .use(Vue3Tour)
  .use(VueMasonryPlugin)
  .use(VueAwesomePaginate)

  .mount("#app");
