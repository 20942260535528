<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <form class="theme-form login-form">
            <h4>Login</h4>
            <h6>Welcome back! Log in to your account.</h6>
            <div class="form-group">
              <label>Username</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="icon-email"> </i>
                </span>
                <input v-model="username" class="form-control" required="" :class="{ 'is-invalid': submitted }" />
                <div v-show="submitted" class="invalid-feedback">Username is required</div>
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="icon-lock"> </i>
                </span>
                <input
                  @keyup.enter="login"
                  v-model="password"
                  autocomplete=""
                  class="form-control"
                  :type="type"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  :class="{ 'is-invalid': submitted && !email }"
                />
                <div v-show="submitted && !password" class="invalid-feedback">Email is required</div>
                <div class="show-hide">
                  <span class="show" @click="showPassword"> </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox p-0 mb-3 w-100">
                <input id="checkbox1" type="checkbox" />
                <label class="text-muted" for="checkbox1">Remember password</label>
              </div>
              <button class="btn btn-primary btn-block" type="button" @click="login">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
// import Userauth from '../auth/js/index';
import axios from "axios";
export default {
  name: "login",
  data() {
    return {
      endpoint: process.env.VUE_APP_API_URL,
      type: "password",
      token: [],
      key: null,
      email: "",
      password: "",
      username: "",
      passwordjwt: "",
      submitted: false,
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },

  mounted() {
    // this.keygen()
    // this.$store.dispatch('authentication/logout');
  },
  methods: {
    handleSubmit() {
      alert("submit");
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch("authentication/login", { username, password });
      }
    },

    async keygen() {
      let body = {
        grantType: "client_credentials",
        clientId: "7a54f560-532e-4521-adb7-e03c660cf798",
        clientSecret: "tnSonKPnNDiVwk3slBQiupIilH8SoaAqsE5B6iMM",
      };
      const x = await axios.post(this.endpoint + "/admin-api/authentication/token", body);
      this.token = x.data.data;
    },

    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },

    async login() {
      let data = {
        username: this.username,
        password: this.password,
        clientId: this.$store.state.client_id_password,
        clientSecret: this.$store.state.client_secret_password,
        grantType: "password",
      };
      await axios
        .post("/admin-api/authentication/login", data)
        .then((response) => {
          if (response.data.error == false) {
            localStorage.setItem("islogged", "true");
            let expToken = response.data.data.authorization.expiresIn;
            let today = new Date();
            expToken = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 10);
            localStorage.setItem("tokenExpiry", expToken);
            localStorage.setItem("loggedIn", "true");
            localStorage.setItem("token", response.data.data.authorization.accessToken);
            this.$store.state.token = response.data.data.authorization.accessToken;
            axios
              .get("/admin-api/account/information", {
                headers: { Authorization: "Bearer " + response.data.data.authorization.accessToken },
              })
              .then((x) => {
                this.$swal({
                  toast: true,
                  position: "top",
                  showConfirmButton: false,
                  timer: 3000,
                  type: "success",
                  title: "Berhasil Login",
                });
                localStorage.setItem("user", JSON.stringify(x.data.data.user));
                this.$store.state.user = x.data.data.user;
                this.$router.replace("/");
              });
          }
        })
        .catch((err) => {
          this.$swal({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            type: "success",
            title: err.response.data.message,
          });
        });
    },

    signUp() {
      alert("oke", this.username);
      alert("sampe sini");
      const clientId = "7e64b8bc-eb29-4838-96ab-c5fdd32745fe";
      const clientSecret = "9bYVefOMDLgW0yXZuH9GreWVmZrUAC0wWTrYXTQV";

      let data = {
        username: this.username,
        password: this.password,
        clientId: clientId,
        clientSecret: clientSecret,
        grantType: "password",
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': 'Bearer ' + this.state.clientToken,
          // Authentication:
          //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3YTU0ZjU2MC01MzJlLTQ1MjEtYWRiNy1lMDNjNjYwY2Y3OTgiLCJqdGkiOiJmYzA0NjY4NTg4M2U4OGQ0NjRjMTM4Y2UxZDJjNWMzNmY1ZGM1MDMxNTlmNWQ1MTEzZWJlMDEyMTFhZDQxZDEyNDRjOWRkOTEyYjJlOTQzYSIsImlhdCI6MTY5MzgwOTM2NS41NDI1NTksIm5iZiI6MTY5MzgwOTM2NS41NDI1NjIsImV4cCI6MTcwOTUzNDE2NS41MzU1NTUsInN1YiI6IiIsInNjb3BlcyI6WyJhcHAtYWRtaW5pc3RyYXRvciJdfQ.BGu2VL7G0sP1L3Ny1rOIX7J4Q_lfu4h-CvMzwLG557Rwsuyx0t4rAFvivTdXGy_LM4aJF2vEAauzVUFKRgLr_9Kpn5EQ0cjuAhR0kgC42U6t9zvGIQrEj6e5DvNoKLTEcSq2oLcpD58iJVe9VNH8yiTR1-Qr0H6swmiPyjFhT_zOJeyxKLGHwAqb6Rc13ddj4U_eJkyoQwsLfjpTJmMoOyF59QxgqmmpuDoVtI53hfGi_OlvRAOghJkWLV7ySpk3IOkGzK2W2rDpBd5nG8KvPtU-Q2RxytGO2QTWA6KOsZe7VIYJrjA390VzJx6CuLraNaw4Ig6RH1X3tDx1dSbgZHwxWSzh0TH2i02iOgvdKh6N2ayBj_AgubmOrUD6NFb69SjCPKDFPYBUPAB8epbxzFdnfzk06JqDFIQkKbQpUT2DVe2lF4iByNzcKgEP1Ny5NnN2azodm7qU9_dxcYzooZ-pIFpxAaiPaU7pR0G1GHbG6W6_0j5v8sJCeRq2p2TGGnuwygVJvn7A4aV6YK8gszvHXL66SE8OIXuT9OcONQbyxd9GKRPFDdmoI5CNLzRJY1I2vDwcZYTSvYv1TYDBdBouIQTZYChiJE2AsAT82C-xWOLycC52rCqHfLj-clHDYRfNw_YmXgJq692QZA6v7c4IZfnRs4vxQN5yDfvIwRk",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      };

      return fetch(`${process.env.VUE_APP_API_URL}/admin-api/authentication/login`, requestOptions)
        .then(handleResponse)
        .then((user) => {
          if (user.token) {
            localStorage.setItem("user", JSON.stringify(user));
          }

          return user;
        });
      // if (this.email === '' && this.password === '') {
      //   (this.email = 'test@admin.com'), (this.password = 'test@123456');
      // } else {
      //   firebase
      //     .auth()
      //     .signInWithEmailAndPassword(this.email, this.password)
      //     .then(
      //       (result) => {
      //         Userauth.localLogin(result);
      //         this.$router.replace('/');
      //       },
      //       (err) => {
      //         (this.email = 'test@admin.com'), (this.password = 'test@123456');
      //         this.$toast.show('Oops...' + err.message, {
      //           theme: 'bubble',
      //           position: 'bottom-right',
      //           type: 'error',
      //           duration: 2000,
      //         });
      //       }
      //     );
      // }
    },

    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace("/");
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
    socialLoginFacebook() {
      const provider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace("/");
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
    socialLoginTwitter() {
      const provider = new firebase.auth.TwitterAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace("/");
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
    socialLoginGit() {
      const provider = new firebase.auth.GithubAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          Userauth.localLogin(result);
          this.$router.replace("/");
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
  },
};
</script>
