<template>
  <div
    class="modal fade"
    id="delModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Konfirmasi</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label>Anda akan menghapus banner customer</label>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Batal</button>
          <button class="btn btn-success" type="button" data-bs-dismiss="modal" @click="deleteData">Ya</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      data: [],
    };
  },
  methods: {
    deleteData() {
      let idbanner = this.$store.state.BannerCustomers.id;
      let customerids = this.$store.state.BannerDeleteIds;
      let data = JSON.stringify({
        bannerId: idbanner,
        customerIds: customerids,
      });

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_API_URL}/admin-api/banner/remove-banner-customer`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: data,
      };

      axios.request(config).then((response) => {
        if (response) {
          this.$toast.show(" Banner customer dihapus ", {
            theme: "outline",
            position: "top-right",
            type: "success",
            duration: 2000,
          });
          this.$store.state.getdataCustomers();
        }
      });
    },
  },
};
</script>
