<template>
  <div class="row">
    <div class="col-sm-14 col-md-6 col-lg-4">
      <div class="ribbon-wrapper card">
        <div class="card-body">
          <div class="ribbon ribbon-primary ribbon-right">Ribbon</div>
          <p>{{ Lorem }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-14 col-md-6 col-lg-4">
      <div class="ribbon-wrapper card">
        <div class="card-body">
          <div class="ribbon ribbon-secondary ribbon-right">Ribbon</div>
          <p>{{ Lorem }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-14 col-md-6 col-lg-4">
      <div class="ribbon-wrapper card">
        <div class="card-body">
          <div class="ribbon ribbon-success ribbon-right">Ribbon</div>
          <p>{{ Lorem }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-14 col-md-6 col-lg-4">
      <div class="ribbon-wrapper card">
        <div class="card-body">
          <div class="ribbon ribbon-info ribbon-right">Ribbon</div>
          <p>{{ Lorem }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-14 col-md-6 col-lg-4">
      <div class="ribbon-wrapper card">
        <div class="card-body">
          <div class="ribbon ribbon-warning ribbon-right">Ribbon</div>
          <p>{{ Lorem }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-14 col-md-6 col-lg-4">
      <div class="ribbon-wrapper card">
        <div class="card-body">
          <div class="ribbon ribbon-danger ribbon-right">Ribbon</div>
          <p>{{ Lorem }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Lorem:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
    };
  },
};
</script>
