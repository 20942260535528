<template>
  <Breadcrumbs main="Bonus UI" title="drag and  drop" />
  <div class="container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Basic Drag & Drop</h5>
      </div>
      <div class="card-body">
        <div class="drag-drop-vue row">
          <draggable class="pull-left col-sm-6">
            <div class="drag-drop-item" v-for="text in one" :key="text">{{ text }}</div>
          </draggable>
          <draggable class="pull-right col-sm-6">
            <div class="drag-drop-item" v-for="text in two" :key="text">{{ text }}</div>
          </draggable>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header pb-0">
        <h5>Copy Drag & Drop</h5>
      </div>
      <div class="card-body">
        <div class="drag-drop-vue row">
          <draggable class="pull-left col-sm-6">
            <div class="drag-drop-item" v-for="text in colOne" :key="text">{{ text }}</div>
          </draggable>
          <draggable class="pull-right col-sm-6">
            <div class="drag-drop-item" v-for="text in colTwo" :key="text">{{ text }}</div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";
export default {
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      one: [
        "Lorem ipsum dolor sit amet, consectetur",
        "sed do eiusmod tempor incididunt ut labore et dolore magna.",
        "Ut enim ad minim veniam, quis nostrud exercitation",
        "ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      ],
      two: [
        "Duis aute irure dolor in reprehenderit in voluptate velit esse",
        "cillum dolore eu fugiat nulla pariatur.",
        "Excepteur sint occaecat cupidatat non proident,",
        "sunt in culpa qui officia deserunt mollit anim id est laborum.",
      ],
      colOne: [
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
        "Aenean commodo ligula eget dolor. Aenean massa.",
        "Lorem ipsum dolor sit amet, consectetur",
        "sed do eiusmod tempor incididunt ut labore et dolore magna.",
        "Ut enim ad minim veniam, quis nostrud exercitation",
        "ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      ],
      colTwo: [
        "Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        "Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.",
        "Duis aute irure dolor in reprehenderit in voluptate velit esse",
        "cillum dolore eu fugiat nulla pariatur.",
        "Excepteur sint occaecat cupidatat non proident,",
        "sunt in culpa qui officia deserunt mollit anim id est laborum.",
      ],
    };
  },
};
</script>
