<template>
  <div
    class="modal fade"
    id="imageModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="imageModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="imageModalLabel">Preview</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img :src="image" class="img-thumbnail mb-2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      image: this.$store.state.ImageBanner,
    };
  },
  mounted() {
    this.$store.state.setImage = this.setImage;
  },
  methods: {
    setImage() {
      this.image = this.$store.state.ImageBanner;
    },
  },
};
</script>
