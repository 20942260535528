<template>
  <h4 class="mt-4">Categories</h4>
  <form>
    <div class="product-group">
      <div class="row">
        <div class="col-sm-12">
          <div class="mb-3">
            <label class="form-label">Product Category</label>
            <select class="form-select">
              <option>Select..</option>
              <option>Man's Shirt</option>
              <option>Man's Jeans</option>
              <option>Women T-shirt</option>
              <option>Women Skirt</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="mb-3">
            <label class="form-label">Brand Icons</label>
            <select class="form-select">
              <option>Select..</option>
              <option>Levi's</option>
              <option>Hudson</option>
              <option>Denizen</option>
              <option>Spykar</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="mb-3">
            <label class="form-label">Color</label>
            <select class="form-select">
              <option>Select..</option>
              <option>Black</option>
              <option>Red</option>
              <option>Blue</option>
              <option>White</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mb-3">
            <label class="form-label">Quality</label>
            <select class="form-select">
              <option>Brand New</option>
              <option>Second Hand</option>
              <option>Both Quality</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
