import BodyView from "@/layout/BodyView.vue";

import salesmanRoutes from "./salesman";

const partnerRoutes = [
  {
    path: "/partner",
    component: BodyView,
    children: [...salesmanRoutes],
  },
];

export default partnerRoutes;
