<template>
  <div class="card">
    <div class="card-header pb-0">
      <h5>Default buttons</h5>
      <span>Add <code>.btn-pill</code> and <code>.btn-air-*</code> class for raised button</span>
    </div>
    <div class="card-body btn-showcase">
      <button
        class="btn btn-pill btn-primary btn-air-primary"
        type="button"
        title="btn btn-pill btn-primary btn-air-primary"
      >
        Primary Button
      </button>
      <button
        class="btn btn-pill btn-secondary btn-air-secondary"
        type="button"
        title="btn btn-pill btn-secondary btn-air-secondary"
      >
        Secondary Button
      </button>
      <button
        class="btn btn-pill btn-success btn-air-success"
        type="button"
        title="btn btn-pill btn-success btn-air-success"
      >
        Success Button
      </button>
      <button class="btn btn-pill btn-info btn-air-info" type="button" title="btn btn-pill btn-info btn-air-info">
        Info Button
      </button>
      <button
        class="btn btn-pill btn-warning btn-air-warning"
        type="button"
        title="btn btn-pill btn-warning btn-air-warning"
      >
        Warning Button
      </button>
      <button
        class="btn btn-pill btn-danger btn-air-danger"
        type="button"
        title="btn btn-pill btn-danger btn-air-danger"
      >
        Danger Button
      </button>
      <button class="btn btn-pill btn-light btn-air-light" type="button" title="btn btn-pill btn-light btn-air-light">
        Light Button
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header pb-0">
      <h5>Large buttons</h5>
      <span>Add <code>.btn-pill</code>,<code>.btn-air-*</code> and <code>.btn-lg</code> class for large button</span>
    </div>
    <div class="card-body btn-showcase">
      <button
        class="btn btn-pill btn-primary btn-air-primary btn-lg"
        type="button"
        title="btn btn-pill btn-primary btn-air-primary btn-lg"
      >
        Primary Button
      </button>
      <button
        class="btn btn-pill btn-secondary btn-air-secondary btn-lg"
        type="button"
        title="btn btn-pill btn-secondary btn-air-secondary btn-lg"
      >
        Secondary Button
      </button>
      <button
        class="btn btn-pill btn-success btn-air-success btn-lg"
        type="button"
        title="btn btn-pill btn-success btn-air-success btn-lg"
      >
        Success Button
      </button>
      <button
        class="btn btn-pill btn-info btn-air-info btn-lg"
        type="button"
        title="btn btn-pill btn-info btn-air-info btn-lg"
      >
        Info Button
      </button>
      <button
        class="btn btn-pill btn-warning btn-air-warning btn-lg"
        type="button"
        title="btn btn-pill btn-warning btn-air-warning btn-lg"
      >
        Warning Button
      </button>
      <button
        class="btn btn-pill btn-danger btn-air-danger btn-lg"
        type="button"
        title="btn btn-pill btn-danger btn-air-danger btn-lg"
      >
        Danger Button
      </button>
      <button
        class="btn btn-pill btn-light btn-air-light btn-lg"
        type="button"
        title="btn btn-pill btn-light btn-air-light btn-lg"
      >
        Light Button
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header pb-0">
      <h5>Small buttons</h5>
      <span>Add <code>.btn-pill</code>,<code>.btn-air-*</code> and <code>.btn-sm</code> class for small button</span>
    </div>
    <div class="card-body btn-showcase">
      <button
        class="btn btn-pill btn-primary btn-air-primary btn-sm"
        type="button"
        title="btn btn-pill btn-primary btn-air-primary btn-sm"
      >
        Primary Button
      </button>
      <button
        class="btn btn-pill btn-secondary btn-air-secondary btn-sm"
        type="button"
        title="btn btn-pill btn-secondary btn-air-secondary btn-sm"
      >
        Secondary Button
      </button>
      <button
        class="btn btn-pill btn-success btn-air-success btn-sm"
        type="button"
        title="btn btn-pill btn-success btn-air-success btn-sm"
      >
        Success Button
      </button>
      <button
        class="btn btn-pill btn-info btn-air-info btn-sm"
        type="button"
        title="btn btn-pill btn-info btn-air-info btn-sm"
      >
        Info Button
      </button>
      <button
        class="btn btn-pill btn-warning btn-air-warning btn-sm"
        type="button"
        title="btn btn-pill btn-warning btn-air-warning btn-sm"
      >
        Warning Button
      </button>
      <button
        class="btn btn-pill btn-danger btn-air-danger btn-sm"
        type="button"
        title="btn btn-pill btn-danger btn-air-danger btn-sm"
      >
        Danger Button
      </button>
      <button
        class="btn btn-pill btn-light btn-air-light btn-sm"
        type="button"
        title="btn btn-pill btn-light btn-air-light btn-sm"
      >
        Light Button
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header pb-0">
      <h5>Extra Small buttons</h5>
      <span
        >Add <code>.btn-pill</code>,<code>.btn-air-*</code> and <code>.btn-xs</code> class for extra small button</span
      >
    </div>
    <div class="card-body btn-showcase">
      <button
        class="btn btn-pill btn-primary btn-air-primary btn-xs"
        type="button"
        title="btn btn-pill btn-primary btn-air-primary btn-xs"
      >
        Primary Button
      </button>
      <button
        class="btn btn-pill btn-secondary btn-air-secondary btn-xs"
        type="button"
        title="btn btn-pill btn-secondary btn-air-secondary btn-xs"
      >
        Secondary Button
      </button>
      <button
        class="btn btn-pill btn-success btn-air-success btn-xs"
        type="button"
        title="btn btn-pill btn-success btn-air-success btn-xs"
      >
        Success Button
      </button>
      <button
        class="btn btn-pill btn-info btn-air-info btn-xs"
        type="button"
        title="btn btn-pill btn-info btn-air-info btn-xs"
      >
        Info Button
      </button>
      <button
        class="btn btn-pill btn-warning btn-air-warning btn-xs"
        type="button"
        title="btn btn-pill btn-warning btn-air-warning btn-xs"
      >
        Warning Button
      </button>
      <button
        class="btn btn-pill btn-danger btn-air-danger btn-xs"
        type="button"
        title="btn btn-pill btn-danger btn-air-danger btn-xs"
      >
        Danger Button
      </button>
      <button
        class="btn btn-pill btn-light btn-air-light btn-xs"
        type="button"
        title="btn btn-pill btn-light btn-air-light btn-xs"
      >
        Light Button
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header pb-0">
      <h5>Active Buttons</h5>
      <span>Add <code>.active</code> for active state</span>
    </div>
    <div class="card-body btn-showcase">
      <button
        class="btn btn-pill btn-primary btn-air-primary active"
        type="button"
        title="btn btn-pill btn-primary btn-air-primary active"
      >
        Active
      </button>
      <button
        class="btn btn-pill btn-secondary btn-air-secondary active"
        type="button"
        title="btn btn-pill btn-secondary btn-air-secondary active"
      >
        Active
      </button>
      <button
        class="btn btn-pill btn-success btn-air-success active"
        type="button"
        title="btn btn-pill btn-success btn-air-success active"
      >
        Active
      </button>
      <button
        class="btn btn-pill btn-info btn-air-info active"
        type="button"
        title="btn btn-pill btn-info btn-air-info active"
      >
        Active
      </button>
      <button
        class="btn btn-pill btn-warning btn-air-warning active"
        type="button"
        title="btn btn-pill btn-warning btn-air-warning active"
      >
        Active
      </button>
      <button
        class="btn btn-pill btn-danger btn-air-danger active"
        type="button"
        title="btn btn-pill btn-danger btn-air-danger active"
      >
        Active
      </button>
      <button
        class="btn btn-pill btn-light btn-air-light active txt-dark"
        type="button"
        title="btn btn-pill btn-light btn-air-light active"
      >
        Active
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header pb-0">
      <h5>Disabled buttons</h5>
      <span>Add <code>.disabled</code> class or <code>disabled="disabled"</code> attribute for disabled button</span>
    </div>
    <div class="card-body btn-showcase">
      <button class="btn btn-pill btn-primary btn-air-primary disabled" type="button">Disabled</button>
      <button class="btn btn-pill btn-secondary btn-air-secondary disabled" type="button">Disabled</button>
      <button class="btn btn-pill btn-success btn-air-success disabled" type="button">Disabled</button>
      <button class="btn btn-pill btn-info btn-air-info disabled" type="button">Disabled</button>
      <button class="btn btn-pill btn-warning btn-air-warning disabled" type="button">Disabled</button>
      <button class="btn btn-pill btn-danger btn-air-danger disabled" type="button">Disabled</button>
      <button class="btn btn-pill btn-light btn-air-light disabled" type="button">Disabled</button>
    </div>
  </div>
</template>
