<template>
  <Breadcrumbs main="Ecommerce" title="Product Details" />
  <detailsView />
</template>
<script>
import detailsView from "./detailsView.vue";
export default {
  components: {
    detailsView,
  },
};
</script>
