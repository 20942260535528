import Jobs from "../../data/jobs.json";

const state = {
  jobs: Jobs.jobs,
};

export default {
  namespaced: true,
  state,
};
