<template>
  <div class="container-fluid dashboard-default-sec">
    <div class="row">
      <div class="col-xl-5 box-col-12 des-xl-100">
        <div class="row">
          <div class="col-xl-12 col-md-6 box-col-6 des-xl-50">
            <div class="card profile-greeting">
              <div class="card-body text-center p-t-0">
                <h3 class="font-light">Welcome Back, {{ Profile.firstName }}!!</h3>
                <button class="btn btn-light">Update</button>
              </div>
              <div class="confetti">
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
              </div>
            </div>
          </div>

          <annualIncome />
          <annualLosses />
        </div>
      </div>
      <salesOverview />
      <growthOverview />
      <userActivations />
    </div>
  </div>
</template>
<script>
import annualIncome from "../default/annualIncome.vue";
import annualLosses from "../default/annual_Losses.vue";
import growthOverview from "../default/growthOverview.vue";
import salesOverview from "../default/salesOverview.vue";
import userActivations from "../default/userActivations.vue";
export default {
  components: {
    annualIncome,
    annualLosses,
    growthOverview,
    salesOverview,
    userActivations,
  },
  data() {
    return {
      Profile: [],
    };
  },
  mounted() {
    this.Profile = JSON.parse(localStorage.getItem("user"));
  },
};
</script>
