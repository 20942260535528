const menu = [
  {
    headTitle1: "Mastering Product",
    type: "headtitle",
  },
  {
    title: "Product",
    icon: "box",
    type: "sub",
    badgeType: "light-secondary",
    badgeValue: "new",
    active: false,
    children: [
      {
        path: "/product/list",
        title: "Product List",
        type: "link",
      },
      {
        path: "/product-brand/list",
        title: "Product Brand",
        type: "link",
      },
      {
        path: "/product-category/list",
        title: "Product Category",
        type: "link",
      },
      {
        path: "/product-uom/list",
        title: "Product Uom",
        type: "link",
      },
      {
        title: "Product Price",
        type: "sub",
        children: [
          {
            path: "/product-price/distributor-list",
            title: "Distributor",
            type: "link",
          },
          {
            path: "/product-price/customer-distributor-list",
            title: "Customer Distributor",
            type: "link",
          },
        ],
      },
    ],
  },
];

export default menu;
