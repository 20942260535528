<template>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 25</h6>
    <div class="loader-box">
      <div class="loader-25"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 26</h6>
    <div class="loader-box">
      <div class="loader-26"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 27</h6>
    <div class="loader-box">
      <div class="loader-29"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 28</h6>
    <div class="loader-box">
      <div class="loader-30"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 29</h6>
    <div class="loader-box">
      <div class="loader-31"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 30</h6>
    <div class="loader-box">
      <div class="loader-32"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 31</h6>
    <div class="loader-box">
      <div class="loader-34"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 32</h6>
    <div class="loader-box">
      <div class="loader-35"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 33</h6>
    <div class="loader-box">
      <div class="loader-37"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 34</h6>
    <div class="loader-box">
      <div class="loader-38"></div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <h6 class="sub-title mb-0 text-center">Loader 35</h6>
    <div class="loader-box">
      <div class="loader-39"></div>
    </div>
  </div>
</template>
