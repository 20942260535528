<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5>Solid Border</h5>
        <span>
          Example of a <code>solid</code> border inside table <code>thead</code>. This border inherits all styling
          options from the default border style, the only difference is it has <code>2px</code> width. Sometimes it
          could be useful for visual separation and addition highlight. To use this border add
          <code>.border-solid</code> to the table head row. This border style works only with row borders.
        </span>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="border-solid">
              <th scope="col">#</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Username</th>
            </tr>
          </thead>
          <tbody v-for="item in items" :key="item">
            <tr>
              <th scope="row">{{ item.no }}</th>
              <td>{{ item.firstname }}</td>
              <td>{{ item.lastname }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.role }}</td>
              <td>{{ item.country }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        { no: 1, firstname: "Mark", lastname: "Otto", username: "@mdo" },
        { no: 2, firstname: "Jacob", lastname: "Thornton", username: "@fat" },
        { no: 3, firstname: "Larry", lastname: "the Bird", username: "@twitter" },
      ],
    };
  },
};
</script>
