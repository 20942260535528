<template>
  <div class="col-sm-12 col-xl-12">
    <div class="card card-overflow">
      <div class="card-header pb-0">
        <h5>Basic Color Dropdown</h5>
      </div>
      <div class="card-body dropdown-basic">
        <div class="dropdown">
          <div class="btn-group mb-0">
            <button class="dropbtn btn-primary" type="button">
              Action
              <span>
                <i class="icofont icofont-arrow-down"> </i>
              </span>
            </button>
            <div class="dropdown-content">
              <a href="#">Action</a>
              <a href="#">Another Action</a>
              <a href="#">Something Else Here</a>
              <a href="#">Separated Link </a>
            </div>
          </div>
        </div>
        <div class="dropdown">
          <div class="btn-group mb-0">
            <button class="dropbtn btn-secondary" type="button">
              Action
              <span>
                <i class="icofont icofont-arrow-down"> </i>
              </span>
            </button>
            <div class="dropdown-content">
              <a href="#">Action</a>
              <a href="#">Another Action</a>
              <a href="#">Something Else Here</a>
              <a href="#">Separated Link </a>
            </div>
          </div>
        </div>
        <div class="dropdown">
          <div class="btn-group mb-0">
            <button class="dropbtn btn-success" type="button">
              Action
              <span>
                <i class="icofont icofont-arrow-down"> </i>
              </span>
            </button>
            <div class="dropdown-content">
              <a href="#">Action</a>
              <a href="#">Another Action</a>
              <a href="#">Something Else Here</a>
              <a href="#">Separated Link </a>
            </div>
          </div>
        </div>
        <div class="dropdown">
          <div class="btn-group mb-0">
            <button class="dropbtn btn-info" type="button">
              Action
              <span>
                <i class="icofont icofont-arrow-down"> </i>
              </span>
            </button>
            <div class="dropdown-content">
              <a href="#">Action</a>
              <a href="#">Another Action</a>
              <a href="#">Something Else Here</a>
              <a href="#">Separated Link </a>
            </div>
          </div>
        </div>
        <div class="dropdown">
          <div class="btn-group mb-0">
            <button class="dropbtn btn-warning txt-dark" type="button">
              Action
              <span>
                <i class="icofont icofont-arrow-down"> </i>
              </span>
            </button>
            <div class="dropdown-content">
              <a href="#">Action</a>
              <a href="#">Another Action</a>
              <a href="#">Something Else Here</a>
              <a href="#">Separated Link </a>
            </div>
          </div>
        </div>
        <div class="dropdown">
          <div class="btn-group mb-0">
            <button class="dropbtn btn-danger" type="button">
              Action
              <span>
                <i class="icofont icofont-arrow-down"> </i>
              </span>
            </button>
            <div class="dropdown-content">
              <a href="#">Action</a>
              <a href="#">Another Action</a>
              <a href="#">Something Else Here</a>
              <a href="#">Separated Link </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
