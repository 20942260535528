<template>
  <div class="col-xl-6 col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h5>All Borders Color</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .b-primary {
            border: 1px solid #ab8ce4 !important;
          }
          .b-t-primary {
            border-top: 1px solid #ab8ce4 !important;
          }
          .b-b-primary {
            border-bottom: 1px solid #ab8ce4 !important;
          }
          .b-l-primary {
            border-left: 1px solid #ab8ce4 !important;
          }
          .b-r-primary {
            border-right: 1px solid #ab8ce4 !important;
          }
          .b-secondary {
            border: 1px solid #26c6da !important;
          }
          .b-t-secondary {
            border-top: 1px solid #26c6da !important;
          }
          .b-b-secondary {
            border-bottom: 1px solid #26c6da !important;
          }
          .b-l-secondary {
            border-left: 1px solid #26c6da !important;
          }
          .b-r-secondary {
            border-right: 1px solid #26c6da !important;
          }
          .b-success {
            border: 1px solid #00c292 !important;
          }
          .b-t-success {
            border-top: 1px solid #00c292 !important;
          }
          .b-b-success {
            border-bottom: 1px solid #00c292 !important;
          }
          .b-l-success {
            border-left: 1px solid #00c292 !important;
          }
          .b-r-success {
            border-right: 1px solid #00c292 !important;
          }
          .b-danger {
            border: 1px solid #FF5370 !important;
          }
          .b-t-danger {
            border-top: 1px solid #FF5370 !important;
          }
          .b-b-danger {
            border-bottom: 1px solid #FF5370 !important;
          }
          .b-l-danger {
            border-left: 1px solid #FF5370 !important;
          }
          .b-r-danger {
            border-right: 1px solid #FF5370 !important;
          }
          .b-info {
            border: 1px solid #4099ff !important;
          }
          .b-t-info {
            border-top: 1px solid #4099ff !important;
          }
          .b-b-info {
            border-bottom: 1px solid #4099ff !important;
          }
          .b-l-info {
            border-left: 1px solid #4099ff !important;
          }
          .b-r-info {
            border-right: 1px solid #4099ff !important;
          }
          .b-light {
            border: 1px solid #eeeeee !important;
          }
          .b-t-light {
            border-top: 1px solid #eeeeee !important;
          }
          .b-b-light {
            border-bottom: 1px solid #eeeeee !important;
          }
          .b-l-light {
            border-left: 1px solid #eeeeee !important;
          }
          .b-r-light {
            border-right: 1px solid #eeeeee !important;
          }
          .b-dark {
            border: 1px solid #2a3142 !important;
          }
          .b-t-dark {
            border-top: 1px solid #2a3142 !important;
          }
          .b-b-dark {
            border-bottom: 1px solid #2a3142 !important;
          }
          .b-l-dark {
            border-left: 1px solid #2a3142 !important;
          }
          .b-r-dark {
            border-right: 1px solid #2a3142 !important;
          }
          .b-warning {
            border: 1px solid #f3d800 !important;
          }
          .b-t-warning {
            border-top: 1px solid #f3d800 !important;
          }
          .b-b-warning {
            border-bottom: 1px solid #f3d800 !important;
          }
          .b-l-warning {
            border-left: 1px solid #f3d800 !important;
          }
          .b-r-warning {
            border-right: 1px solid #f3d800 !important;
          }
        </pre>
      </div>
    </div>
  </div>
</template>
