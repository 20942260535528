<template>
  <Breadcrumbs sub="Partner" title="Salesman Customer" />

  <div class="card">
    <div class="card-body">
      <div class="d-flex gap-5">
        <div class="d-flex align-items-center gap-1">
          <span>Tampilkan</span>
          <select class="form-select" v-model="itemsPerPage" @change="refetch">
            <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
          </select>

          <button type="button" class="btn btn-light" @click="download">
            <vue-feather size="20" type="download" />
          </button>
        </div>
        <div class="flex-grow-1"></div>
        <div class="d-flex gap-1">
          <input type="search" placeholder="Type to Search" class="form-control" v-model="searchKeyword" />
          <button type="button" class="btn btn-danger" @click="resetFilterQuery">
            <vue-feather size="20" type="x-circle" />
          </button>
        </div>
      </div>

      <div class="table-responsive py-2">
        <table class="table table-sm table-striped table-bordered">
          <thead class="table-dark">
            <tr>
              <th class="text-white">No</th>
              <th class="text-white">Salesman Name</th>
              <th class="text-white">Customer Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customerSalesman in customerSalesmans" :key="customerSalesman.number">
              <td>{{ customerSalesman.number }}</td>
              <td>{{ customerSalesman.salesman.name }}</td>
              <td>{{ customerSalesman.customer.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <span>Menampilkan {{ number_first }} - {{ number_last }} dari {{ items_total }}</span>

        <vue-awesome-paginate
          v-show="customerSalesmans.length"
          v-model="currentPage"
          :total-items="items_total"
          :items-per-page="itemsPerPage"
          :showJumpButtons="true"
          @click="refetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";

import { salesmanService } from "@/_services";

export default {
  data() {
    return {
      customerSalesmans: [],
      customersSalesmanMeta: {},

      pageOptions: [10, 25, 50, 100],
      itemsPerPage: 10,
      currentPage: 1,

      searchKeyword: "",
      filterQuery: {},
    };
  },
  watch: {
    searchKeyword: debounce(function (keyword) {
      this.filterQuery.search = keyword || undefined;
      this.refetch();
    }, 1000),
  },
  computed: {
    number_first() {
      return this.itemsPerPage * (this.currentPage - 1) + 1;
    },
    number_last() {
      return this.number_first - 1 + this.customersSalesmanMeta.count;
    },
    items_total() {
      return this.customersSalesmanMeta.total;
    },
  },
  methods: {
    async refetch() {
      const payload = {
        page: this.currentPage,
        perPage: this.itemsPerPage,
        ...this.filterQuery,
      };
      const { customerSalesmans, meta: customersSalesmanMeta } = await salesmanService.getCustomers(payload);

      this.customerSalesmans = customerSalesmans;
      this.customersSalesmanMeta = customersSalesmanMeta;

      let number = this.number_first;
      this.customerSalesmans.forEach((customerSalesman) => (customerSalesman.number = number++));
    },
    resetFilterQuery() {
      this.filterQuery = {};
      this.refetch();
    },
    download() {
      console.log("download");
    },
  },
  created() {
    this.refetch();
  },
};
</script>
