<template>
  <div class="col-xxl-6 set-col-12 box-col-7 xl-60">
    <div class="row">
      <div class="col-xl-12 col-md-6">
        <div class="card">
          <div class="blog-box blog-list row">
            <div class="col-xl-6 col-12">
              <div class="blog-wrraper">
                <a href="#">
                  <router-link to="/blog/single">
                    <img class="img-fluid sm-100-wp p-0" src="../../../assets/images/blog/blog-5.jpg" alt="" />
                  </router-link>
                </a>
              </div>
            </div>
            <div class="col-xl-6 col-12">
              <div class="blog-details">
                <div class="blog-date"><span>09</span> January 2022</div>
                <a href="#">
                  <router-link to="/blog/single"> <h6>Encounter every day.</h6> </router-link>
                </a>
                <div class="blog-bottom-content">
                  <ul class="blog-social">
                    <li>by: Admin</li>
                    <li>2 Hits</li>
                  </ul>
                  <hr />
                  <p class="mt-0">A huge part of it is the incomparable beauty you can encounter every day.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12 col-md-6">
        <div class="card">
          <div class="blog-box blog-list row">
            <div class="col-xl-6 col-12">
              <div class="blog-wrraper">
                <a href="#">
                  <router-link to="/blog/single">
                    <img class="img-fluid sm-100-w p-0" src="../../../assets/images/blog/blog-6.jpg" alt="" />
                  </router-link>
                </a>
              </div>
            </div>
            <div class="col-xl-6 col-12">
              <div class="blog-details">
                <div class="blog-date"><span>30</span> January 2022</div>
                <a href="#">
                  <router-link to="/blog/single"> <h6>Black color is important.</h6> </router-link>
                </a>
                <div class="blog-bottom-content">
                  <ul class="blog-social">
                    <li>by: Admin</li>
                    <li>0 Hits</li>
                  </ul>
                  <hr />
                  <p class="mt-0">A garden where plants that flower are grown and displayed in floral garden.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
