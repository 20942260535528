<template>
  <div class="mb-2">
    <label class="col-form-label">Disabled Results</label>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
