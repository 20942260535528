import { createRouter, createWebHistory } from "vue-router";
import BodyView from "../layout/BodyView.vue";
import firebase from "firebase";
import Userauth from "../auth/js/index";
import auth from "../auth/auth.vue";
import callback from "../components/callback";

import IndexDefault from "../components/dashboard/default/IndexDefault.vue";
import indexEcommerce from "../components/dashboard/e-commerce/indexEcommerce";

import article_list from "../components/article/article_list.vue";
import article_add from "../components/article/article_add.vue";
import article_edit from "../components/article/article_edit.vue";
import article_customer from "../components/article/article_customer/article_customer.vue";

import banner_list from "../components/banner/banner_list.vue";
import banner_add from "../components/banner/banner_add.vue";
import banner_edit from "../components/banner/banner_edit.vue";
import banner_customer from "../components/banner/banner_customer/banner_customer.vue";

import bank_list from "../components/bank/bank_list.vue";
import bank_add from "../components/bank/bank_add.vue";
import bank_edit from "../components/bank/bank_edit.vue";

import product_list from "../components/product/product_list.vue";
import product_add from "../components/product/product_add.vue";
import product_edit from "../components/product/product_edit.vue";

import product_brand_list from "../components/product/brand/product_brand_list.vue";
import product_brand_add from "../components/product/brand/product_brand_add.vue";
import product_brand_edit from "../components/product/brand/product_brand_edit.vue";

import product_category_list from "../components/product/category/product_category_list.vue";
import product_category_add from "../components/product/category/product_category_add.vue";
import product_category_edit from "../components/product/category/product_category_edit.vue";

import product_uom_list from "../components/product/uom/product_uom_list.vue";
import product_uom_add from "../components/product/uom/product_uom_add.vue";
import product_uom_edit from "../components/product/uom/product_uom_edit.vue";

import product_price_distributor_list from "../components/product/price/product_price_distributor.vue";
import product_price_customer_distributor_list from "../components/product/price/product_price_customer_distributor.vue";

import customer_list from "../components/customer/customer_list.vue";

import distributor_list from "../components/distributor/distributor_list.vue";
import distributor_add from "../components/distributor/distributor_add.vue";
import distributor_edit from "../components/distributor/distributor_edit.vue";

// import coupon_list from '../components/coupon/coupon_list.vue'
// import coupon_add from '../components/coupon/coupon_add.vue'
// import coupon_edit from '../components/coupon/coupon_edit.vue'

import promotion_list from "../components/promotion/promotion_list.vue";
import promotion_add from "../components/promotion/promotion_add.vue";
import promotion_edit from "../components/promotion/promotion_edit.vue";

// import survey_list from '../components/survey/survey_list.vue'
// import survey_add from '../components/survey/survey_add.vue'
// import survey_edit from '../components/survey/survey_edit.vue'

// import delivery_type_list from '../components/delivery_type/delivery_type_list.vue'
// import delivery_type_edit from '../components/delivery_type/delivery_type_edit.vue'

import indexGeneral from "../components/widgets/general/indexGeneral";
import indexChart from "../components/widgets/charts/indexChart";

import indexList from "../components/project/projectlist/indexList";
import createProject from "../components/project/createproject/createProject";

import kanbanBoard from "../components/kanban/kanbanBoard";
import file_manager from "../components/filemaneger/file_manager";

import indexProduct from "../components/ecommerce/product/indexProduct";
import cartView from "../components/ecommerce/cartView";
import indexAdd from "../components/ecommerce/addproduct/indexAdd";
import productDetails from "../components/ecommerce/productDetails/productDetails";
import checkOut from "../components/ecommerce/chekout/checkOut";
import wishList from "../components/ecommerce/wishList";
import invoiceView from "../components/ecommerce/invoice/invoiceView";
import PaymentDetails from "../components/ecommerce/paymentDetails/PaymentDetails";
import orderHistory from "../components/ecommerce/orderhistory/orderHistory";

import indexEmail from "../components/email/indexEmail";

import indexChat from "../components/chat/chatList/indexChat";
import videoChat from "../components/chat/videoChat/videoChat";

import userProfile from "../components/users/profile/userProfile";
import editProfile from "../components/users/editProfile/editProfile";
import cardIndex from "../components/users/userCards/cardIndex";

import bookMark from "../components/bookmark/bookMark.vue";

import socialApp from "../components/socialApp/socialApp";

import todoIndex from "../components/todo/todoIndex";
import firebaseTodo from "../components/firebasetodo/firebaseTodo";

import serchIndex from "../components/search/serchIndex";

import formValidation from "../components/forms/formValidetion/formValidation";
import base_Input from "../components/forms/baseInput/base_Input";
import checkbox_radio from "../components/forms/Checkbox&Radio/checkbox_radio";
import input_groups from "../components/forms/InputGroup/input_groups";
import megaOptions from "../components/forms/megaOptions/megaOptions";

import select2 from "../components/formWidgets/select2/select2";
import switch_From from "../components/formWidgets/switch/switch_From";
import touchspin_Form from "../components/formWidgets/touchspin/touchspin_Form";
import typeahead_Form from "../components/formWidgets/typeahead/typeahead_Form";
import clipboard_Form from "../components/formWidgets/clipboard/clipboard_Form";
import datepicker from "../components/formWidgets/datepicker/datepicker";

import form_wizard from "../components/formwizard/form_wizard";

import basic_tables from "../components/tables/bootstrapTable/basicTables/basic_tables";
import sizing_tables from "../components/tables/bootstrapTable/sizeTable/sizing_tables";
import border_Tables from "../components/tables/bootstrapTable/borderTables/border_Tables";
import styling_table from "../components/tables/bootstrapTable/stylingtable/styling_table";

import basic_Init from "../components/tables/dataTable/BasicInit/basic_Init";

import state_Color from "../components/uikits/statecolor/state_Color";
import typograPhy from "../components/uikits/Typography/typograPhy";
import avatars_Uikits from "../components/uikits/Avatars/avatars_Uikits";
import helper_classes from "../components/uikits/helper/helper_classes";
import grid_Uikits from "../components/uikits/grid/grid_Uikits";
import tag_pills from "../components/uikits/Tag&Pills/tag_pills";
import progress_bar from "../components/uikits/progressBar/progress_bar";
import modal_Uikit from "../components/uikits/modal/modal_Uikit";
import alert_Uikit from "../components/uikits/alert/alert_Uikit";
import popover_Uikit from "../components/uikits/Popover/popover_Uikit";
import tooltip_uikits from "../components/uikits/tooltip/tooltip_uikits";
import spinners_Uikit from "../components/uikits/Spinners/spinners_Uikit";
import accordion_Uikit from "../components/uikits/accordion/accordion_Uikit";
import box_shadow from "../components/uikits/Shadow/box_shadow";
import list_Uikit from "../components/uikits/list/list_Uikit";
import dropdown_Uikit from "../components/uikits/Dropdown/dropdown_Uikit";

import scrollable_advance from "../components/advance/Scrollable/scrollable_advance";
import pagination_advance from "../components/advance/Pagination/pagination_advance";
import sweetAlert from "../components/advance/SweetAlert/sweetAlert";
import ribbons_advance from "../components/advance/Ribbons/ribbons_advance";
import breadCrumb from "../components/advance/Breadcrumb/breadCrumb";
import cropper_advance from "../components/advance/cropper/cropper_advance";
import toaster_advance from "../components/advance/Toaster/toaster_advance";
import tour_advance from "../components/advance/Tour/tour_advance";
import rating_advance from "../components/advance/Rating/rating_advance";
import upload_advance from "../components/advance/upload/upload_advance";
import sticky_advance from "../components/advance/sticky/sticky_advance";
import range_advance from "../components/advance/rangeSlider/range_advance";
import dragdrop from "../components/advance/dragdrop/dragdrop";

import flag_Icon from "../components/icon/flag_Icon";
import feather_icon from "../components/icon/feather_icon";
import weather_icon from "../components/icon/weather_icon";
import themify_icon from "../components/icon/themify_icon";
import font_Awesome from "../components/icon/fontAwesome/font_Awesome";
import icon_Icon from "../components/icon/icon_Icon";

import default_button from "../components/button/Default/default_button";
import flat_button from "../components/button/Flat/flat_button";
import edge_button from "../components/button/Edge/edge_button";
import raised_button from "../components/button/Raised/raised_button";
import button_group from "../components/button/ButtonGroup/button_group";

import google_chart from "../components/charts/googleChart/google_chart";
import apex_chart from "../components/charts/ApexChart/apex_chart";
import chartist_chart from "../components/charts/Chartist/chartist_chart";

import grid_gallery from "../components/gallery/grid_gallery";
import grid_desc from "../components/gallery/grid_desc";
import hover_gallery from "../components/gallery/hover-gallery/hover_gallery";
import masonry_gallery from "../components/gallery/masonry-gallery/masonry_gallery";
import masonary_desc from "../components/gallery/masonary_desc";

import errorPage1 from "../errors/errorPage1.vue";
import errorPage2 from "../errors/errorPage2.vue";
import errorPage3 from "../errors/errorPage3.vue";
import errorPage4 from "../errors/errorPage4.vue";

import BasicView from "../components/cards/basicView/basicView.vue";
import DraggableView from "../components/cards/draggableView";
import creative_card from "../components/cards/creative/creative_card";
import tabbed_card from "../components/cards/Tabbed/tabbed_card";

import login_image from "../components/authentication/login_image";
import login_image2 from "../components/authentication/login_image2";
import ragister_simple from "../components/authentication/ragister_simple";
import ragister_image from "../components/authentication/ragister_image";
import ragister_image2 from "../components/authentication/ragister_image2";
import login_with_validation from "../components/authentication/login_with_validation";
import unlock from "../components/authentication/unlock";
import forget_password from "../components/authentication/forget_password";
import reset_password from "../components/authentication/reset_password";

import timeline from "../components/timeline/timeline";

import ckediter from "../components/editer/ckediter";
import simple_editer from "../components/editer/simple_editer";

import blog_detail from "../components/blog/blog-details/blog_detail";
import blog_single from "../components/blog/blog-single/blog_single";

import job_list from "../components/job/job-list/job_list";
import job_details from "../components/job/job_details/job_details";
import job_apply from "../components/job/job_apply/job_apply";

import learning_list from "../components/learning/learninglist/learning_list";
import coursedetailed from "../components/learning/coursedetailed";

import faqindex from "../components/faq/faqindex";

import login from "../auth/login";

import knowledgebase from "../components/Knowledgebase/knowledgebase";

import support from "../components/support/support";

import pricing from "../components/Pricing/pricing";

import maintenance from "../components/maintenance";

import sample_page from "../components/sample_page";

import calender from "../components/calendar/calender";

import ComingsoonImage from "../components/comingsoon/comingsoon_image";
import ComingsoonSimple from "../components/comingsoon/comingsoon_simple";
import ComingsoonVideo from "../components/comingsoon/comingsoon_video";

import partnerRoutes from "./partner/index";

import dataSyncMaster from "../components/data_sync/master.vue"

const routes = [
  {
    path: "/",
    component: BodyView,

    children: [
      {
        path: "",
        name: "defaultRoot",
        component: IndexDefault,
      },
    ],
  },
  {
    path: "/dashboard",
    component: BodyView,

    children: [
      {
        path: "default",
        name: "defaultIndex",
        component: IndexDefault,
      },
      {
        path: "indexEcommerce",
        name: "E-Commerce",
        component: indexEcommerce,
      },
    ],
  },
  {
    path: "/widgets",
    component: BodyView,
    children: [
      {
        path: "indexGeneral",
        name: "general",
        component: indexGeneral,
      },
      {
        path: "indexchart",
        name: "charts",
        component: indexChart,
      },
    ],
  },
  {
    path: "/project",
    component: BodyView,
    children: [
      {
        path: "indexList",
        name: "projectlist",
        component: indexList,
      },
      {
        path: "createProject",
        name: "createProject",
        component: createProject,
      },
    ],
  },

  {
    path: "/error-page1",
    name: "errorPage1",
    component: errorPage1,
  },
  {
    path: "/error-page2",
    name: "errorPage2",
    component: errorPage2,
  },
  {
    path: "/error-page3",
    name: "errorPage3",
    component: errorPage3,
  },
  {
    path: "/error-page4",
    name: "errorPage4",
    component: errorPage4,
  },

  {
    path: "/cards",
    component: BodyView,
    children: [
      {
        path: "basicView",
        name: "BasicView",
        component: BasicView,
        meta: {
          title: "BootstrapStyling | Cuba - Premium Admin Template",
        },
      },
      {
        path: "draggableView",
        name: "DraggableView",
        component: DraggableView,
        meta: {
          title: "Draggable | Cuba - Premium Admin Template",
        },
      },
      {
        path: "creative",
        name: "creative",
        component: creative_card,
      },
      {
        path: "tabbed",
        name: "tabbed",
        component: tabbed_card,
      },
    ],
  },
  {
    path: "/comingsoon/comingsoon-image",
    name: "ComingsoonImage",
    component: ComingsoonImage,
    meta: {
      title: "ComingsoonImage | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/comingsoon/comingsoon-simple",
    name: "ComingsoonSimple",
    component: ComingsoonSimple,
    meta: {
      title: "ComingsoonSimple | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/comingsoon/comingsoon-video",
    name: "ComingsoonVideo",
    component: ComingsoonVideo,
    meta: {
      title: "ComingsoonVideo | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: maintenance,
  },
  {
    path: "/knowledgebase",
    component: BodyView,
    children: [
      {
        path: "",
        name: "knowledgebase",
        component: knowledgebase,
      },
    ],
  },

  {
    path: "/app",
    component: BodyView,

    children: [
      {
        path: "file_manager",
        name: "defaultView3",
        component: file_manager,
      },
      {
        path: "kanbanBoard",
        name: "defaultView2",
        component: kanbanBoard,
      },
      {
        path: "email",
        name: "email",
        component: indexEmail,
      },
      {
        path: "chat",
        name: "chat",
        component: indexChat,
      },
      {
        path: "videochat",
        name: "videoChat",
        component: videoChat,
      },
      {
        path: "bookmark",
        name: "Bookmark",
        component: bookMark,
      },
      {
        path: "todo",
        name: "todo",
        component: todoIndex,
      },
      {
        path: "firebase-todo",
        name: "firebaseTodo",
        component: firebaseTodo,
      },
      {
        path: "calendar",
        name: "calender",
        component: calender,
      },
    ],
  },
  {
    path: "/pages",
    component: BodyView,
    children: [
      {
        path: "social-app",
        name: "socialapp",
        component: socialApp,
      },
      {
        path: "search",
        name: "search",
        component: serchIndex,
      },
      {
        path: "sample-page",
        name: "samplepage",
        component: sample_page,
      },
      {
        path: "faq",
        name: "faqindex",
        component: faqindex,
      },
      {
        path: "support",
        name: "support",
        component: support,
      },
      {
        path: "pricing",
        name: "pricing",
        component: pricing,
      },
    ],
  },

  {
    path: "/ecommerce",
    component: BodyView,

    children: [
      {
        path: "indexProduct",
        name: "ecommerce",
        component: indexProduct,
      },
      {
        path: "cartView",
        name: "chart",
        component: cartView,
      },
      {
        path: "details/:id",
        name: "prouctDetails",
        component: productDetails,
      },
      {
        path: "checkOut",
        name: "checkout",
        component: checkOut,
      },
      {
        path: "wishList",
        name: "wisthlist",
        component: wishList,
      },
      {
        path: "invoiceView",
        name: "invoice",
        component: invoiceView,
      },
      {
        path: "payment/details",
        name: "payment",
        component: PaymentDetails,
      },
      {
        path: "order/history",
        name: "orderHistory",
        component: orderHistory,
      },
      {
        path: "add-product",
        name: "addproduct",
        component: indexAdd,
      },
    ],
  },
  {
    path: "/users",
    component: BodyView,
    children: [
      {
        path: "/users/profile",
        name: "userProfile",
        component: userProfile,
      },
      {
        path: "/users/edit",
        name: "edit",
        component: editProfile,
      },
      {
        path: "/users/cards",
        name: "userCard",
        component: cardIndex,
      },
    ],
  },
  {
    path: "/form",
    component: BodyView,
    children: [
      {
        path: "validation",
        name: "formValidation",
        component: formValidation,
      },
      {
        path: "inputs",
        name: "basicInput",
        component: base_Input,
      },
      {
        path: "checkbox-radio",
        name: "checkbox & radio",
        component: checkbox_radio,
      },
      {
        path: "input-groups",
        name: "input Groups",
        component: input_groups,
      },
      {
        path: "mega-options",
        name: "megaOptions",
        component: megaOptions,
      },
      {
        path: "select2",
        name: "select2",
        component: select2,
      },
      {
        path: "switch",
        name: "switch",
        component: switch_From,
      },
      {
        path: "touchspin",
        name: "touchspin",
        component: touchspin_Form,
      },
      {
        path: "typeahead",
        name: "typeahead",
        component: typeahead_Form,
      },
      {
        path: "clipboard",
        name: "clipboard",
        component: clipboard_Form,
      },
      {
        path: "wizard",
        name: "formwizard",
        component: form_wizard,
      },
      {
        path: "datepicker",
        name: "datepicker",
        component: datepicker,
      },
    ],
  },
  {
    path: "/article",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "list1",
        component: article_list,
      },
      {
        path: "add",
        name: "add1",
        component: article_add,
      },
      {
        path: "edit",
        name: "edit1",
        component: article_edit,
      },
      {
        path: "customer",
        name: "customer1",
        component: article_customer,
      },
    ],
  },
  {
    path: "/banner",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "banner_list",
        component: banner_list,
      },
      {
        path: "add",
        name: "banner_add",
        component: banner_add,
      },
      {
        path: "edit",
        name: "banner_edit",
        component: banner_edit,
      },
      {
        path: "customer",
        name: "banner_customer",
        component: banner_customer,
      },
    ],
  },
  {
    path: "/bank",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "bank_list",
        component: bank_list,
      },
      {
        path: "add",
        name: "bank_add",
        component: bank_add,
      },
      {
        path: "edit",
        name: "bank_edit",
        component: bank_edit,
      },
    ],
  },

  {
    path: "/product",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "product_list",
        component: product_list,
      },
      {
        path: "add",
        name: "product_add",
        component: product_add,
      },
      {
        path: "edit",
        name: "product_edit",
        component: product_edit,
      },
    ],
  },

  {
    path: "/product-brand",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "product_brand_list",
        component: product_brand_list,
      },
      {
        path: "add",
        name: "product_brand_add",
        component: product_brand_add,
      },
      {
        path: "edit",
        name: "product_brand_edit",
        component: product_brand_edit,
      },
    ],
  },

  {
    path: "/product-category",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "product_category_list",
        component: product_category_list,
      },
      {
        path: "add",
        name: "product_category_add",
        component: product_category_add,
      },
      {
        path: "edit",
        name: "product_category_edit",
        component: product_category_edit,
      },
    ],
  },

  {
    path: "/product-uom",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "product_uom_list",
        component: product_uom_list,
      },
      {
        path: "add",
        name: "product_uom_add",
        component: product_uom_add,
      },
      {
        path: "edit",
        name: "product_uom_edit",
        component: product_uom_edit,
      },
    ],
  },

  {
    path: "/product-price",
    component: BodyView,
    children: [
      {
        path: "distributor-list",
        name: "product_price_distributor_list",
        component: product_price_distributor_list,
      },
      {
        path: "customer-distributor-list",
        name: "product_price_customer_distributor_list",
        component: product_price_customer_distributor_list,
      },
    ],
  },

  {
    path: "/customer",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "customer_list",
        component: customer_list,
      },
    ],
  },

  {
    path: "/distributor",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "distributor_list",
        component: distributor_list,
      },
      {
        path: "add",
        name: "distributor_add",
        component: distributor_add,
      },
      {
        path: "edit",
        name: "distributor_edit",
        component: distributor_edit,
      },
    ],
  },

  {
    path: "/promotion",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "promotion_list",
        component: promotion_list,
      },
      {
        path: "add",
        name: "promotion_add",
        component: promotion_add,
      },
      {
        path: "edit",
        name: "promotion_edt",
        component: promotion_edit,
      },
    ],
  },

  // {
  //   path:"/coupon",
  //   component:BodyView,
  //   children:[
  //     {
  //       path:"list",
  //       name:"coupon_list",
  //       component:coupon_list
  //     },
  //     {
  //       path:"add",
  //       name:"coupon_add",
  //       component:coupon_add
  //     },
  //     {
  //       path:"edit",
  //       name:"coupon_edit",
  //       component:coupon_edit
  //     },
  //   ]
  // },

  // {
  //   path:"/survey",
  //   component:BodyView,
  //   children:[
  //     {
  //       path:"list",
  //       name:"survey_list",
  //       component:survey_list
  //     },
  //     {
  //       path:"add",
  //       name:"survey_add",
  //       component:survey_add
  //     },
  //     {
  //       path:"edit",
  //       name:"survey_edit",
  //       component:survey_edit
  //     },
  //   ]
  // },

  // {
  //   path:"/delivery_type",
  //   component:BodyView,
  //   children:[
  //     {
  //       path:"list",
  //       name:"delivery_type_list",
  //       component:delivery_type_list
  //     },
  //     {
  //       path:"edit",
  //       name:"delivery_type_edit",
  //       component:delivery_type_edit
  //     },
  //   ]
  // },

  {
    path: "/table",
    component: BodyView,
    children: [
      {
        path: "basic",
        name: "basic1",
        component: basic_tables,
      },
      {
        path: "sizing",
        name: "sizing",
        component: sizing_tables,
      },
      {
        path: "border",
        name: "border",
        component: border_Tables,
      },
      {
        path: "styling",
        name: "styling",
        component: styling_table,
      },
      {
        path: "/datatable-basic",
        name: "datatable",
        component: basic_Init,
      },
    ],
  },
  {
    path: "/uikits",
    component: BodyView,
    children: [
      {
        path: "typography",
        name: "typography",
        component: typograPhy,
      },
      {
        path: "avatars",
        name: "avatars",
        component: avatars_Uikits,
      },
      {
        path: "helper-classes",
        name: "helper",
        component: helper_classes,
      },
      {
        path: "grid",
        name: "grid",
        component: grid_Uikits,
      },
      {
        path: "tag-pills",
        name: "tag&pills",
        component: tag_pills,
      },
      {
        path: "progress-bar",
        name: "progressbar",
        component: progress_bar,
      },
      {
        path: "modal",
        name: "modal",
        component: modal_Uikit,
      },
      {
        path: "alert",
        name: "alert",
        component: alert_Uikit,
      },
      {
        path: "popover",
        name: "popover",
        component: popover_Uikit,
      },
      {
        path: "tooltip",
        name: "tooltip",
        component: tooltip_uikits,
      },
      {
        path: "loader",
        name: "spinners",
        component: spinners_Uikit,
      },
      {
        path: "accordion",
        name: "accordion",
        component: accordion_Uikit,
      },
      {
        path: "box-shadow",
        name: "boxshadow",
        component: box_shadow,
      },
      {
        path: "lists",
        name: "list",
        component: list_Uikit,
      },
      {
        path: "dropdown",
        name: "dropdown",
        component: dropdown_Uikit,
      },
    ],
  },
  {
    path: "/advance",
    component: BodyView,
    children: [
      {
        path: "scrollable",
        name: "scrollable",
        component: scrollable_advance,
      },
      {
        path: "pagination",
        name: "pagination",
        component: pagination_advance,
      },
      {
        path: "sweetalert",
        name: "sweetAlert",
        component: sweetAlert,
      },
      {
        path: "ribbons",
        name: "ribbons",
        component: ribbons_advance,
      },
      {
        path: "breadcrumb",
        name: "breadCrumb",
        component: breadCrumb,
      },
      {
        path: "image-cropper",
        name: "cropper",
        component: cropper_advance,
      },
      {
        path: "toastr",
        name: "toaster",
        component: toaster_advance,
      },
      {
        path: "tour",
        name: "tour",
        component: tour_advance,
      },
      {
        path: "rating",
        name: "rating",
        component: rating_advance,
      },
      {
        path: "upload",
        name: "upload",
        component: upload_advance,
      },
      {
        path: "sticky",
        name: "sticky",
        component: sticky_advance,
      },
      {
        path: "range-slider",
        name: "rangeslider",
        component: range_advance,
      },
      {
        path: "dragdrop",
        name: "dragdrop",
        component: dragdrop,
      },
    ],
  },
  {
    path: "/icons",
    component: BodyView,
    children: [
      {
        path: "flag",
        name: "flag",
        component: flag_Icon,
      },
      {
        path: "feather_icon",
        name: "feather",
        component: feather_icon,
      },
      {
        path: "whether",
        name: "weather",
        component: weather_icon,
      },
      {
        path: "themify",
        name: "themify",
        component: themify_icon,
      },
      {
        path: "fontawesome",
        name: "FontAwesome",
        component: font_Awesome,
      },
      {
        path: "ico",
        name: "icon",
        component: icon_Icon,
      },
    ],
  },
  {
    path: "/buttons",
    component: BodyView,
    children: [
      {
        path: "default",
        name: "default",
        component: default_button,
      },
      {
        path: "flat",
        name: "flat",
        component: flat_button,
      },
      {
        path: "edge",
        name: "edge",
        component: edge_button,
      },
      {
        path: "raised",
        name: "raised",
        component: raised_button,
      },
      {
        path: "group",
        name: "button_group",
        component: button_group,
      },
    ],
  },
  {
    path: "/chart",
    component: BodyView,
    children: [
      {
        path: "google",
        name: "googlechart",
        component: google_chart,
      },
      {
        path: "apexChart",
        name: "apexchart",
        component: apex_chart,
      },
      {
        path: "chartist",
        name: "chartist",
        component: chartist_chart,
      },
    ],
  },
  {
    path: "/gallery",
    component: BodyView,
    children: [
      {
        path: "grid-gallery",
        name: "gridgallery",
        component: grid_gallery,
      },
      {
        path: "gallery-desc",
        name: "griddesc",
        component: grid_desc,
      },
      {
        path: "hover-effect",
        name: "hovergallery",
        component: hover_gallery,
      },
      {
        path: "gallery-masonary",
        name: "masonry-gallery",
        component: masonry_gallery,
      },
      {
        path: "gallery-masonary-desc",
        name: "masonary-desc",
        component: masonary_desc,
      },
    ],
  },

  {
    path: "/auth",
    component: auth,
    children: [
      {
        path: "login",
        name: "login",
        component: login,
      },
    ],
  },
  {
    path: "/callback",
    name: "callback",
    component: callback,
    meta: {
      title: "Auth0",
    },
  },
  {
    path: "/authentication/login/one",
    name: "login-image",
    component: login_image,
  },
  {
    path: "/authentication/login/two",
    name: "login-image2",
    component: login_image2,
  },
  {
    path: "/authentication/register/image",
    name: "ragister-image",
    component: ragister_image,
  },
  {
    path: "/authentication/register/image2",
    name: "ragister-image2",
    component: ragister_image2,
  },
  {
    path: "/auth/register",
    name: "ragister_simple",
    component: ragister_simple,
  },
  {
    path: "/authentication/login/validate",
    name: "login-validation",
    component: login_with_validation,
  },
  {
    path: "/authentication/unlockuser",
    name: "unlock",
    component: unlock,
  },
  {
    path: "/authentication/forgetpassword",
    name: "forget-password",
    component: forget_password,
  },
  {
    path: "/authentication/resetpassword",
    name: "reset-password",
    component: reset_password,
  },
  {
    path: "/timeline",
    component: BodyView,
    children: [
      {
        path: "one",
        name: "timeline",
        component: timeline,
      },
    ],
  },
  {
    path: "/editor",
    component: BodyView,
    children: [
      {
        path: "ck-editor",
        name: "ckediter",
        component: ckediter,
      },
      {
        path: "simple-editor",
        name: "simple-editer",
        component: simple_editer,
      },
    ],
  },
  {
    path: "/blog",
    component: BodyView,
    children: [
      {
        path: "details",
        name: "blog-detail",
        component: blog_detail,
      },
      {
        path: "single",
        name: "blog_single",
        component: blog_single,
      },
    ],
  },
  {
    path: "/job",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "listview",
        component: job_list,
      },
      {
        path: "/job/details/:id",
        name: "jobdetails",
        component: job_details,
        props: true,
      },
      {
        path: "apply/:id",
        name: "jobapply",
        component: job_apply,
      },
    ],
  },
  {
    path: "/learning",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "learninglist",
        component: learning_list,
      },
      {
        path: "details/:id",
        name: "coursedetailed",
        component: coursedetailed,
      },
    ],
  },
  {
    path: "/data_sync",
    component: BodyView,
    children: [
      {
        path: "master",
        name: "master",
        component: dataSyncMaster,
      }
    ],
  },
  ...partnerRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  // firebase.auth().onAuthStateChanged(() => {
  //   if (to.meta.title) document.title = to.meta.title;

  //   const CurrentUser = firebase.auth().currentUser;

  //   const path = ["/auth/login", "/auth/register"];
  //   if (path.includes(to.path)) return next();

  //   const Auth = localStorage.getItem("user");
  //   if (Auth == null) return next("/auth/login");

  //   if (localStorage.getItem("user")) return next();

  //   return next("/auth/login");
  // });

  return next();
});

export default router;
