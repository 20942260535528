<template>
  <Breadcrumbs main="Article" title=" Article Customer" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <!-- filter and search  -->
                <div class="justify-content-between mb-0 mt-2">
                  <div class="row">
                    <div class="col-8 mt-1">
                      <div class="input-group class-form">
                        <input
                          type="search"
                          style="width: 100% !important"
                          placeholder="Pencarian"
                          class="form-control"
                          @keyup.enter="cari()"
                          v-model="filterQuery"
                        />
                      </div>
                    </div>

                    <div class="col-4 mt-2">
                      <div class="d-flex">
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-success me-3"
                            @click="cari()"
                            size="20"
                            type="search"
                          >
                          </vue-feather>
                        </div>
                        <div v-if="selectedData.length == 0">
                          <vue-feather
                            class="btn btn-xs btn-outline-primary me-3"
                            @click="resetcari"
                            size="20"
                            type="x-square"
                          >
                          </vue-feather>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="position-absolute top-0 end-0">
                  <ul class="fled d-flex">
                    <li v-if="selectedData.length == 0">
                      <router-link
                        to="/article/customer"
                        data-container="body"
                        data-bs-placement="top"
                        data-bs-toggle="modal"
                        data-original-title="test"
                        data-bs-target="#largeModal"
                        title="Add"
                        @click="save"
                      >
                        <vue-feather class="btn btn-xs btn-outline-success me-3" type="plus"> </vue-feather>
                      </router-link>
                    </li>

                    <li v-else>
                      <router-link
                        data-bs-toggle="modal"
                        data-original-title="test"
                        data-bs-target="#exampleModal"
                        to="/article/customer"
                        data-container="body"
                        data-placement="top"
                        title="Delete"
                        @click="hapus"
                      >
                        <vue-feather class="btn btn-xs btn-outline-primary" type="trash"> </vue-feather>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover" id="tabledt" v-show="items.length > 0">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      v-model="selectedAll"
                      class="big_checkbox"
                      id="ceklishead"
                      @click="selectAll()"
                    />&nbsp;
                  </th>
                  <th scope="col">No</th>
                  <th scope="col">Kode Toko</th>
                  <th scope="col">Nama Toko</th>
                  <th scope="col">No Telp</th>
                  <th scope="col">Region</th>
                  <th scope="col">Area</th>
                  <th scope="col">Alamat</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td><input type="checkbox" class="big_checkbox" @click="getRow" id="ceklis" />&nbsp;</td>
                  <td>{{ item.no }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    {{ item.phone }}
                  </td>
                  <td>
                    {{ item.region }}
                  </td>
                  <td>
                    {{ item.area }}
                  </td>
                  <td>{{ item.address }}</td>
                </tr>
              </tbody>
            </table>
            <div class="loader-box" v-show="isLoading == true">
              <div class="loader-2"></div>
            </div>
            <div v-show="isLoading == false && items.length == 0" class="px-4">
              <span>Tidak ada data</span>
            </div>
          </div>

          <!-- row and pagination  -->
          <div class="flex">
            <div class="d-flex justify-content-end mb-2">
              <div class="mt-4 mx-3">
                <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
              </div>

              <!-- pagination  -->
              <div class="mt-4 mx-3">
                <vue-awesome-paginate
                  v-show="items.length > 0 && isload == false"
                  :total-items="meta.total"
                  :items-per-page="meta.perPage"
                  :max-pages-shown="meta.perPage"
                  v-model="page"
                  @click="changePage"
                />
              </div>

              <div class="mt-n1 mx-4">
                <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                  <p>
                    <small>Tampilkan</small>
                    <select
                      name="basic-1_length"
                      aria-controls="basic-1"
                      class="form-control"
                      @change="showper"
                      v-model="perPage"
                    >
                      <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of row and pagination  -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal  -->
  <AddArticle />
  <DeleteArticle />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import AddArticle from "@/components/article/article_customer/article_customer_add.vue";
import DeleteArticle from "@/components/article/article_customer/article_customer_delete.vue";

export default {
  components: {
    VueFeather,
    DeleteArticle,
    AddArticle,
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      No: 0,
      firstNo: null,
      lastNo: null,
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      selectedAll: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      NoPage: 1,
      items: [],
      dataArticleCustomers: [],
      dataTag: [],
      perPage: 10,
      isload: false,
      filterQuery: "",
      firstNo: null,
      lastNo: null,
      selectedData: [],
      removedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
    };
  },

  mounted() {
    this.user = localStorage.getItem("user");
    this.getArticleCustomer();
    this.$store.state.getdataCustomers = this.getArticleCustomer;
  },
  methods: {
    async getdata() {
      await axios
        .get("/admin-api/customer/list-customers?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.customers.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.customers;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.selectedAll = false;

          this.getArticleCustomer();
        });
    },

    resetcari() {
      this.filterQuery = "";
      this.getArticleCustomer();
    },

    getArticleCustomer() {
      this.isLoading = true;
      this.dataArticleCustomers = [];
      this.items = [];
      if (this.$store.state.ArticleCustomers == undefined) {
        this.$router.replace("/article/list");
      } else {
        axios
          .get("/admin-api/article/list-article-customers?articleId=" + this.$store.state.ArticleCustomers.id, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((x) => {
            let num = this.perPage * this.page;
            this.firstNo = num - this.perPage + 1;
            this.No = this.page * this.perPage;
            this.No = this.No - this.perPage;
            this.meta = x.data.data.meta;
            this.$store.state.metaArticleCustomer = this.meta;
            x.data.data.articleCustomers.forEach((el) => {
              this.items.push(el.customer);
            });
            this.items.forEach((z) => {
              this.No++;
              z.no = this.No;
            });
            this.lastNo = this.No;
            this.isLoading = false;
            this.selectedAll = false;
            this.fetchRow();
          });
      }
    },

    selectAll() {
      this.selectedAll = !this.selectedAll;
      var grid = document.getElementById("tabledt");
      let data = [];
      var checkBoxes = grid.getElementsByTagName("INPUT");
      for (var i = 0; i < checkBoxes.length; i++) {
        checkBoxes[i].checked = this.selectedAll;
        if (i != 0 && checkBoxes[i].checked == true) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
    },

    async fetchRow() {
      var grid = document.getElementById("tabledt");
      let data = await this.dataArticleCustomers;
      var checkBoxes = grid.getElementsByTagName("INPUT");
      for (var i = 0; i < checkBoxes.length; i++) {
        if (data.findIndex((z) => z.id == checkBoxes[i].parentNode.parentNode.cells[0].innerText) != -1) {
          checkBoxes[i].checked = true;
        }
      }
    },

    hapus() {
      if (this.selectedData.length > 0) {
        this.$store.state.ArticleDeleteId = this.$store.state.ArticleCustomers.id;
        this.$store.state.CustomerDeleteIds = this.selectedData;
      }
    },

    getRow() {
      var grid = document.getElementById("tabledt");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let datalist = this.dataArticleCustomers;
      let data = [];
      let undata = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (
          checkBoxes[i].checked &&
          datalist.findIndex((z) => z.id == checkBoxes[i].parentNode.parentNode.cells[0].innerText) == -1
        ) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
        if (
          checkBoxes[i].checked == false &&
          datalist.findIndex((z) => z.id == checkBoxes[i].parentNode.parentNode.cells[0].innerText) != -1
        ) {
          undata.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
      this.removedData = undata;
    },

    filter() {
      let params = {
        articleTags: this.valueTag,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get(
          "/admin-api/article/list-article-customers?articleId=" +
            this.$store.state.ArticleCustomers.id +
            "&perPage=" +
            this.perPage +
            "&" +
            valTag[0],
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articleCustomers.forEach((el) => {
            this.items.push(el.customer);
          });
          this.items.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.filteritems = this.items;
          this.meta = x.data.data.meta;
          this.filterOpt = true;
        });
    },

    reset() {
      this.selectedData = [];
      this.removedData = [];
    },

    cari() {
      this.isLoading = true;
      this.items = [];
      axios
        .get(
          "/admin-api/article/list-article-customers?articleId=" +
            this.$store.state.ArticleCustomers.id +
            "&page=1" +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articleCustomers.forEach((el) => {
            this.items.push(el.customer);
          });
          this.items.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        });
    },

    changePage(x) {
      this.isLoading = true;
      this.items = [];
      this.page = x;
      axios
        .get(
          "/admin-api/article/list-article-customers?articleId=" +
            this.$store.state.ArticleCustomers.id +
            "&page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articleCustomers.forEach((el) => {
            this.items.push(el.customer);
          });
          this.items.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
          this.fetchRow();
        });
    },

    showper() {
      this.isLoading = true;
      this.items = [];
      this.NoPage = 1;
      axios
        .get(
          "/admin-api/article/list-article-customers?articleId=" +
            this.$store.state.ArticleCustomers.id +
            "&page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.articleCustomers.forEach((el) => {
            this.items.push(el.customer);
          });
          this.items.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.meta = x.data.data.meta;
          this.fetchRow();
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.big_checkbox {
  width: 1.2rem;
  height: 1.2rem;
  top: 0.5rem;
}
</style>
