<template>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Animated radio buttons</h5>
      </div>
      <div class="card-body animate-chk">
        <div class="row">
          <div class="col">
            <label class="d-block" for="edo-ani">
              <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani" checked="" />Option 1
            </label>
            <label class="d-block" for="edo-ani1">
              <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />Option 2
            </label>
            <label class="d-block" for="edo-ani2">
              <input class="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked="" />Option 3
            </label>
            <label class="d-block" for="edo-ani13">
              <input class="radio_animated" id="edo-ani13" type="radio" name="rdo-ani" />Option 4
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
