import order from "../../data/order.json";

const state = {
  product: order.product,
};

export default {
  namespaced: true,
  state,
};
