<template>
  <div class="col-sm-12 col-xl-6 col-lg-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Basic Switch</h5>
      </div>
      <div class="card-body row switch-showcase height-equal">
        <div class="col-sm-12">
          <div class="media">
            <label class="col-form-label m-r-10">Default Switch</label>
            <div class="media-body text-end">
              <label class="switch"> <input type="checkbox" checked="" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Unhecked Switch</label>
            <div class="media-body text-end">
              <label class="switch"> <input type="checkbox" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">With Icon</label>
            <div class="media-body text-end icon-state">
              <label class="switch"> <input type="checkbox" checked="" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Unhecked With Icon</label>
            <div class="media-body text-end icon-state">
              <label class="switch"> <input type="checkbox" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Disabled State</label>
            <div class="media-body text-end">
              <label class="switch"> <input type="checkbox" disabled="" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
          <div class="media">
            <label class="col-form-label m-r-10">Disabled With Icon</label>
            <div class="media-body text-end icon-state">
              <label class="switch"> <input type="checkbox" disabled="" /> <span class="switch-state"> </span> </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
