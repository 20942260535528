<template>
  <div class="col-xl-6 col-lg-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Vertical Align</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .baseline {
            vertical-align: baseline;
          }
          .sub {
            vertical-align: sub;
          }
          .super {
            vertical-align: super;
          }
          .top {
            vertical-align: top;
          }
          .text-top {
            vertical-align: text-top;
          }
          .middle {
            vertical-align: middle;
          }
          .bottom {
            vertical-align: bottom;
          }
          .text-bottom {
            vertical-align: text-bottom;
          }
          .initial {
            vertical-align: initial;
          }
          .inherit {
            vertical-align: inherit;
          }
        </pre>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-lg-12 col-md-6">
    <div class="card">
      <div class="card-header pb-0">
        <h5>Image Sizes</h5>
      </div>
      <div class="card-body">
        <pre class="helper-classes">
          .img-10 {
            width: 10px !important;
          }
          .img-20 {
            width: 20px !important;
          }
          .img-30 {
            width: 30px !important;
          }
          .img-40 {
            width: 40px !important;
          }
          .img-50 {
            width: 50px !important;
          }
          .img-60 {
            width: 60px !important;
          }
          .img-70 {
            width: 70px !important;
          }
          .img-80 {
            width: 80px !important;
          }
          .img-90 {
            width: 90px !important;
          }
          .img-100 {
            width: 100px !important;
          }
        </pre>
      </div>
    </div>
  </div>
</template>
